import barCss from '~css/bar.scss'
// import iconDeleteBorder from '~img/icon-delete-border.png'
// import iconDeleteBorder from '~lib/2022_moon_menu_close.svg' // 節日彩蛋使用 (中秋節)
// import iconDeleteBorder from '~img/img_CNY_menu_close.svg'   // 新年
// import iconDeleteBorder from '~lib/img_Xmas_menu_close.svg' // 聖誕節
// import iconDeleteBorder from '~lib/2023_new_year_menu_close.svg' // 2023新年
// const iconDeleteBorder = `${process.env.SERVER_URL}/img/icon-delete-border.png`
const iconDeleteBorder = `${process.env.SERVER_URL}/lib/2022_moon_menu_close.svg` // 節日彩蛋使用 (中秋節)
// const iconDeleteBorder = `${process.env.SERVER_URL}/lib/2023_new_year_menu_close.svg`

const navigationTemp = () => `
        <!--漢堡線-->
        <a class="${barCss.barIcon} ${barCss.barIcon_menu}" style="cursor: pointer" data-gtm-whitebar="browsing_diversion-ham-menu"></a>

        <!--所有產品+logo+icon-->
        <div id="heybarNavigation" class="${barCss.mainMenu}">
            <!--logo(M版出現-未開啟導航選單時)-->
            <logo class="${barCss['bar_logo--short']}"></logo>
            

            <!--產品選單-->
            <nav class="${barCss.leftNav}" style='pointer-events:none'>
                <root-nav></root-nav>
                <leaf-nav-small-menu></leaf-nav-small-menu>
                <!--第二層選單-->
                <div class="${barCss['nav-content']}"  style='pointer-events:auto'>
                    <!--PC版出現-->
                    <leaf-nav></leaf-nav>

                    <!--M版出現-->
                    <div class="${barCss['nav-MB']}">
                        <!--M版固定logo區塊 (開啟導航選單時)-->
                        <div class="${barCss['bar-logo__sm']}">
                            <!--關閉icon-->
                            <button class="${barCss.iconClose}" style="cursor: pointer;">
                                <img src="${iconDeleteBorder}" width="24"/>
                            </button>
                            <!--M版logo-->
                            <logo class="${barCss.subLogo}"></logo>
                        </div>
                        <!--M版選單列表內容-->
                        <leaf-m-nav></leaf-m-nav>
                    </div>
                </div> 
            </nav>

            <!--關閉icon-->
            <!-- <a href="#" class="barIcon barIcon_close"></a> -->
        </div>
`


// const navigationTemp2 = () => `
// <!--漢堡線-->
// <a href="#" class="${barCss.barIcon} ${barCss.barIcon_menu}" data-gtm-sub-param="M版漢堡條"></a>

// <!--所有產品+logo+icon-->
// <!--加上class"mainMenu--open"M版選單會出現-->
// <div id="heybarNavigation" class="${barCss.mainMenu}">
//     <!--logo(M版出現-開啟導航選單時)-->
//     <logo class="${barCss['bar_logo--sm']} ${barCss['bar-logo__left']}"></logo>

//     <!--產品選單-->
//     <!-- <div class="${barCss.mainMenu_box}" id="heybarNavigationList"> -->
//     <nav class="${barCss['priority-nav']}" id="heybarNavigationList">
//     </nav>

//     <!--關閉icon-->
//     <a href="#" class="${barCss.barIcon} ${barCss.barIcon_close}"></a>
// </div>
// `

export {
    navigationTemp,
}