import barCss from '~css/bar.scss'

const logoutNameTemp = (mode) => `
<a class="${barCss.personal_name}" id="heybarPersonalOpen" data-gtm-whitebar="browsing_user-icon" data-gtm-value="FALSE" style="cursor:pointer; white-space:nowrap;${mode==='simpleLogout'? ' background: none;' : ''}">
{{loginText}}
</a>`
const loginNameTemp = () => `
<a class="${barCss.personal_name}" id="heybarPersonalOpen" data-gtm-whitebar="browsing_user-icon" data-gtm-value="TRUE" style="cursor:pointer;">
    <div class="${barCss.personal_name_inner}" title="{{name}}" id="heybarPersonalNameOnBar">{{name}}</div>
</a>`

const loginOfficialServiceTemp = () => `
        <!--3個連結-->
        <div class="${barCss.personal_member}">
            <a class="${barCss.personal_member_item}" id='heybarLogoutLink' style="cursor:pointer" data-gtm-whitebar="logout_user-logout">{{logoutText}}</a>
        </div>
`
const linksBeforeLoginTemp = () => `
        <!--常見問題+會員規約+聯絡小編-->
        <div class="${barCss.personal_qa}" id="heybarPersonalLinksBeforeLoginContent">
        </div>
`
/**
 * 填空：domain, headshot, productName, name, intro, loginPrompt, loginText, logoutText
 */
const personalContentTemp = (mode) => `

    <!--個人內容-->
    <div class="${barCss.personal_content}">
        <!--關閉icon-->
        <!-- <a class="${barCss.barIcon} ${barCss.barIcon_close}" id="heybarPersonalClose" style="cursor: pointer;"></a> -->
        <!--標題-->
        <div class="${barCss.personal_title}"><personal-product-name>{{productName}}</personal-product-name></div>

        <!--頭像+職稱+手刀登入-->
        <div class="${barCss.personal_info}" id="heybarPersonalInfo" data-gtm-whitebar="${mode === 'login' ? 'read' : 'login'}_user-card">
            <img src="{{headshot}}" alt="" class="${barCss.personal_photo}" id="heybarPersonalHeadshot">
            <div class="${barCss.personal_data}">
                <p class="${mode === 'login' ? barCss.personal_nickname : barCss.personal_login + ' heybarLoginLink'}" id="heybarPersonalName">
                ${mode === 'login' ? '{{name}}' : '{{loginPrompt}} <i class="' + barCss.jb_icon_right + '"></i>'}
                </p>
                <p class="${barCss.personal_jobName}">{{intro}}</p>
                {{prompt}}
            </div>
        </div>
        ${mode === 'logout' ? linksBeforeLoginTemp() : ''}
        ${mode === 'login' ? '<div style="overflow-y: auto"><product-function></product-function></div>' + loginOfficialServiceTemp() : ''}
    </div>
`

const personalTemp = (mode) => `
<!--加上class"personal--close"個人內容會隱藏-->
<div class="${barCss.personal} ${barCss['personal--close']} heybarPersonalContent" id="heybarPersonal">
    ${mode === 'login' ? loginNameTemp() : logoutNameTemp(mode)}
    <!--頭像-->
    <a id="heybarSmall" class="${barCss.personal_avatar}" style="cursor: pointer" data-gtm-whitebar="browsing_user-icon" data-gtm-value="${mode === 'login' ? 'TRUE' : 'FALSE'}">
        <!--加上class"avatar-active"有橘色圈-->
        <img src="{{headshot}}" alt="" id="heybarPersonalAvatar">
        <!--向上的三角形-->
        <!--加上class"border-up-personal--close"三角形會隱藏-->
        <!--
        <div class="${barCss['border-up-personal']} ${barCss['border-up-personal--close']}" id="heybarPersonalArrowUp">
            <span></span>
        </div>
        -->
    </a>
    ${mode !== 'simpleLogout' ? personalContentTemp(mode) : ''}
</div>`
//<div class="${barCss.personal_info}" id="heybarPersonalInfo" data-gtm-sub-param="profile-頭像與姓名${mode === 'login' ? '' : '-未登'}">
export {
    personalTemp,
}