import Notification from '@/n11s'
import { setTokenUri, getInitialData, getLoginInitialData, setEnhanceSecurity, setApplyTokenWithCredentials, setBeforeApplyToken } from '@/api'
import { eventMixin } from '@/mixins'
import mainTemp    from '@/templates/main'
import Navigation  from '@/components/navigation'
import Personal    from '@/components/personal'
import Search      from '@/components/search'
import SearchInput from '@/components/search-input'
import Notify      from '@/components/notify'
import Logo        from '@/components/logo'
import OneWordBtn  from '@/components/one-word-btn'
import ShoppingCart from '@/components/shopping-cart'
import CustomFeature from '@/components/custom-feature'
import NotificationHelper from '@/helper/notification-helper'
import { transProductId, isNil }   from '@/utils/tools'
import { getCookie, deleteCookie } from '@/utils/cookie'
import eventBus                    from '@/utils/event-bus'
import * as SystemEvents           from '@/constants/system-event'
import * as HeybarEvents           from '@/constants/heybar-event'
import * as modeHelper             from '@/helper/heybar-mode-helper'
import barCss                      from '~css/bar.scss'
import { UseN11s }                 from '@/constants/heybar-config'

const { debug, error} = require('@/utils/logger')(__filePath, __fileName, '#009FCC')

const EVENT_INITIATED = 'heybar/initiated'  // heybar 初始化事件名
const EVENT_LOGIN     = 'heybar/login'      // heybar 登入事件名
const EVENT_LOGOUT    = 'heybar/logout'     // heybar 登出登件名
const NAVI_CACHE_NAME = 'heybarNaviCache'   // heybar 導航存入 cookie 的 key name

const defaultConfig = {
    container                : null, // 黑bar要畫在那個區塊內
    tokenUri                 : null, // 向產品取得黑bar token 的 url
    applyTokenWithCredentials: null, // (boolean)(optional) 取得 token 時，發出的 xhr 是否要帶 withCredentials = true
    beforeApplyToken         : null, // (function)(optional) 在取出取得 token 的請求前，會呼叫此 function 並帶入 axios 的設定檔，若有需要在取得 token 前處理事情可傳入此 function
    logo                     : null, // 產品 logo 圖示的 url
    logoAction               : null, // 點擊 logo 所要導向的網址(string)或要執行的方法(function)
    login                    : null, // 按下黑bar上的登入時，所要導向的網址(string)或要執行的方法(function)
    logout                   : null, // 按下黑bar上的登出時，所要導向的網址(string)或要執行的方法(function)
    search                   : null, // 按下黑bar上的搜尋時，所要導向的網址(string)或要執行的方法(function)
    hotkeys                  : [],   // search 的熱門關鍵字, 內為 object：{link, text}
    displaySearch            : null, // 顯示 search 的時機，always: 總是顯示，afterLogin: 登入後才顯示，不顯示不要填此欄位
    searchPlaceholder        : null, // 顯示在 search 輸入框內的 placeholder 文字，若不輸入則預設使用['搜尋' + 產品名]
    searchValue              : ''  , // 顯示在 search 輸入框上的文字
    loginInfo                : null, // 登入的資訊 (name, headshot, email, productFunc)
    naviAction               : null, // (function) 按下導航連結時會呼叫此參數傳入的 function
    drawWhen                 : 'loading', // 何時開始繪製。loading: container 一生成即開始, domReady: dom ready 後才開始
    linksBeforeLogin         : [],   // 登入前要顯示的連結，內為 object：{link, text}。選填
    linksBeforeLoginAction   : null, // (function) 按下 linksBeforeLogin 內的連結時會呼叫此參數傳入的 function
    displayOneWord           : null, // 顯示一字鈕的時機, always: 總是顯示，afterLogin: 登入後才顯示，預設是 always
    oneWord                  : null, // 一字鈕的"字", 不顯示不要填此欄位
    onwWordAction            : null, // (function) 按下一字鈕時要呼叫此 function
    loginSlogan              : null, // (string) 登入標語
    es                       : null, // (string|function)(optional) 安全性字串 or 產生安全性字串的 function。傳入的字串會在呼叫 tokenUrl 時放在 header 的 x-heybar-es 傳入，在user未登入時可傳入一次性使用的亂數字串來確保是由 heybar js 所呼叫
    suggestion               : null, // (string|function)(optional) 向產品詢問 suggestion 的 url 或 function
    suggestActivateNum       : 2,    // (int) 當 suggestion 有輸入時，key 入幾個字後啟動 suggestion
    productName              : null, // (string)(optional) 產品名稱。有輸入此參數，則用此參數覆蓋掉從資料庫取出的產品名稱
    hideWhileScroll          : false,
    hideNaviM                : false, // (boolean)(optional) 隱藏 M 版導航列
    prodNaviM                : [],    // 產品自定義的導航項(只出現在 M 版)，內為 object：{link, text}
    logoutText               : '登出',
    loginText                : '登入/註冊',
    loginPrompt              : '登入/新會員註冊',
    moreServiceText          : '104更多服務',
    twoColumnsNavi           : false, // (boolean)(optional) 導航列是否要分成兩欄 (預設為3欄)
}

// 若 browser 不支援原生的 TextDecoder 則用自己寫的
if (!window.TextDecoder) {
    require('@/utils/text-encoder-decoder')
}
class HeyBar {
    constructor(config) {
        this.conf = Object.assign({}, defaultConfig, config)

        // 一定要有 container
        if (!this.conf.container) {
            throw Error('new HeyBar FAIL!! container is null')
        }

        // 一定要有取 access token 的 url
        if (!this.conf.tokenUri) {
            throw Error('new HeyBar FAIL!! tokenUri is null')
        } else {
            setTokenUri(this.conf.tokenUri)
        }

        // 有 es 字串傳設定到 api
        if (this.conf.es) {
            setEnhanceSecurity(this.conf.es)
        }

        if (this.conf.applyTokenWithCredentials != null) {
            setApplyTokenWithCredentials(this.conf.applyTokenWithCredentials)
        }

        if (this.conf.beforeApplyToken) {
            setBeforeApplyToken(this.conf.beforeApplyToken)
        }

        // oneWord 如果有填字的話
        if (this.conf.oneWord) {
            // 長度只能 1
            if (this.conf.oneWord.length > 0) {
                this.conf.oneWord = this.conf.oneWord.substring(0, 4)
            }

            // displayOneWord 如果沒填，則預設是 always
            if (!this.conf.displayOneWord) {
                this.conf.displayOneWord = 'always'
            }
        } else if (this.conf.displayOneWord) {
            throw Error('new HeyBar FAIL!! conf.oneWord must have value when conf.displayOneWord have value')
        }

        // console.log("plugins::", this.conf.plugins)
        this.pluginMap   = {always:[], afterLogin:[], beforeLogin: []}
        this.pluginNames = []
        if (this.conf.plugins) {
            for (const plugin of this.conf.plugins) {
                if (isNil(plugin.name) || plugin.name === '') {
                    throw Error('plugin must have a name')
                }

                if (plugin.name === 'shoppingCart' && isNil(plugin.icon)) {
                    plugin.icon = [`${process.env.SERVER_URL}/img/ic-24-px-cart.svg`, `${process.env.SERVER_URL}/img/ic-24-px-cart-hover.svg`]
                }

                
                if (isNil(plugin.icon)) {
                    throw Error('plugin must have icons')
                }

                // 整型 plugin.icon 設定
                if (typeof(plugin.icon) === 'string') {
                    plugin.icon = [plugin.icon, plugin.icon]
                } else if (plugin.icon.length === 1) {
                    plugin.icon = [plugin.icon[0], plugin.icon[0]]
                }
                
                // 向下相容前一版 plugin.click 的設定
                if (plugin.click && !plugin.handler) {
                    plugin.handler = plugin.click
                }

                this.pluginNames.push(plugin.name)

                // 整型 plugin.dropdown 設定
                const _dropdown = {'content': null, closeAfterClick: true}
                if (!isNil(plugin.dropdown)) {
                    if (!isNil(plugin.dropdown.content)) {
                        _dropdown.content = plugin.dropdown.content
                    }
                    if (!isNil(plugin.dropdown.closeAfterClick)) {
                        _dropdown.closeAfterClick = plugin.dropdown.closeAfterClick
                    }
                }
                plugin.dropdown = _dropdown
                
                // 整型 plugin.handler 設定
                const _handler = {pc: null, mobile: null}
                if (!isNil(plugin.handler)) {
                    if (typeof(plugin.handler) === 'string') {
                        const _link   = plugin.handler  // 因為 plugin.handler 後續會被轉成 plugin.handler = {pc: xx, mobile: xx}
                                                      // 所以如果下面寫成 () => {window.open(plugin.handler, '_blank')} 時
                                                      // 當事件觸發時，plugin.handler 已經變成 {pc: xx, mobile: xx} 而不是字串了
                                                      // 若不先把 string 存下來的話就會導致抓不到正確的 url 了。
                        _handler.pc     = () => {window.open(_link, '_blank')}
                        _handler.mobile = () => {window.open(_link, '_blank')}
                    
                    } else if (typeof(plugin.handler) === 'function') {
                        _handler.pc     = plugin.handler
                        _handler.mobile = plugin.handler
                    
                    } else if (typeof(plugin.handler) === 'object') {
                        if (plugin.handler.pc) {
                            if (typeof(plugin.handler.pc) === 'string') {
                                const _link = plugin.handler.pc
                                _handler.pc = () => {window.open(_link, '_blank')}
                            } else {
                                _handler.pc = plugin.handler.pc
                            }
                        }
                        if (plugin.handler.mobile) {
                            if (typeof(plugin.handler.mobile) === 'string') {
                                const _link = plugin.handler.mobile
                                _handler.mobile = () => {window.open(_link, '_blank')}
                            } else {
                                _handler.mobile = plugin.handler.mobile
                            }
                        }
                    }
                }
                plugin.handler = _handler

                // 整型 displayWhen 設定
                if (isNil(plugin.displayWhen)) {
                    plugin.displayWhen = null
                } else if (typeof(plugin.displayWhen) === 'string') {
                    plugin.displayWhen = plugin.displayWhen.toUpperCase()
                } else {
                    throw Error('"displayWhen" must be "always", "afterLogin" and "beforeLogin"')
                }

                // 如果存在 plugin.action，表示是舊的設定，將它轉型成新格式
                if (plugin.action) {
                    plugin.dropdown.content = plugin.action.dropdown || (plugin.action.pc && plugin.action.pc.dropdown) || (plugin.action.mobile && plugin.action.mobile.dropdown) || plugin.dropdown.content
                    plugin.dropdown.closeAfterClick = plugin.action.closeAfterClick || (plugin.action.pc && plugin.action.pc.closeAfterClick) || (plugin.action.mobile && plugin.action.mobile.closeAfterClick) || plugin.dropdown.closeAfterClick

                    plugin.handler.pc     = plugin.action.link || (plugin.action.pc && plugin.action.pc.link) || null
                    plugin.handler.mobile = plugin.action.link || (plugin.action.mobile && plugin.action.mobile.link) || null

                    if (typeof(plugin.handler.pc) === 'string') {
                        plugin.handler.pc = () => {window.open(plugin.handler.pc, '_blank')}
                    } else if (typeof(plugin.handler.pc) !== 'function') {
                        plugin.handler.pc = null
                    }

                    if (typeof(plugin.handler.mobile) === 'string') {
                        plugin.handler.mobile = () => {window.open(plugin.handler.mobile, '_blank')}
                    } else if(typeof(plugin.handler.mobile) !== 'function') {
                        plugin.handler.mobile = null
                    }
                }
                
                if (isNil(plugin.displayWhen) || plugin.displayWhen === 'ALWAYS') {
                    this.pluginMap.always.push(plugin)
                } else if (plugin.displayWhen === 'AFTERLOGIN') {
                    this.pluginMap.afterLogin.push(plugin)
                } else if (plugin.displayWhen === 'BEFORELOGIN') {
                    this.pluginMap.beforeLogin.push(plugin)
                }

                if (isNil(plugin.triggerEvent)) {
                    plugin.triggerEvent = 'CLICK'
                } else {
                    plugin.triggerEvent = plugin.triggerEvent.toUpperCase()
                    if (plugin.triggerEvent !== 'CLICK' && plugin.triggerEvent !== 'HOVER') {
                        throw Error('triggerEvent must be "click" or "hover"')
                    }
                }

                if (isNil(plugin.useArrow) || typeof(plugin.useArrow) !== 'boolean') {
                    plugin.useArrow = false
                }
                
            }
        }
        console.log("pluginMap::", this.pluginMap)

        this.isLogin     = false // 是否已登入
        this.productId   = null  // 目前的產品代碼
        this.productName = this.conf.productName || ''    // 目前的產品名稱
        this.useN11s     = UseN11s.DISABLE // 是否使用"通知"
        this.initData    = null  // 初始 heybar 的資料
        this.n11sHelper  = null  // 通知使用的 helper 物件
        this.hideAnimStatus = 'done' // 隱藏 heybar 的動畫狀態
        this.scrollval      = 0      // 捲軸位置
        this.isOpen         = false  // 目前的 heybar 是否有開啟的視窗
        this.fireOpenOrCloseEventTimer = null // 發出 heybar open/close 事件的 timer (用 timer 是為了避掉抖動的現象)
        this.bodyScrollY    = null
        this.bodyPosition   = null
        this.isBodyScrollLock = false
        this.lock4SetScrollY  = false

        // const regexp = /http[s]?:\/\/(?:(?:\w+\.)*(\w+)\.104(?:-dev|-staging)?\.com\.tw|([\w\.\:]+))/g
        // const matcher = regexp.exec(location.href)
        // this.domain = matcher[1] || matcher[2]
        this.domain = null

        // =======> 節日彩蛋使用 START <========================================================
        this.lottiePromise = new Promise(function(resolve, reject) {
            const headTag  = document.querySelector('head')
            const lottieJs =  document.createElement('script')
            lottieJs.src   = `${process.env.SERVER_URL}/lib/lottie.min.js`,
            lottieJs.onload = () => { resolve() }
            if (headTag) headTag.appendChild(lottieJs)
            else document.body.appendChild(lottieJs)
        })
        // =======> 節日彩蛋使用 END <========================================================

        this.initiate(getInitialData()) // 為了加快heybar呈現速度一開始就先去抓來初始化資料
    }

    initiate(initialData) {
        debug('HeyBar initiate')
        
        // 把主要 node 建出來
        this.mainNode = document.createElement('div')
        this.mainNode.classList.add(barCss.heybarContainer)
        this.mainNode.setAttribute('id', 'heybarContainer')
        this.mainNode.dataset.random = new Date().getTime() + "-" + Math.random()

        // new 出各個元件
        // this.navigation = new Navigation(this.mainNode, data.navigation)
        // this.personal = new Personal(this.mainNode, this.conf.login, this.conf.logout, this.productName, data.official_services)
        // 
        this.navigation  = new Navigation(this.mainNode, {beforeDraw: (node) => {new Logo(node, this.conf).draw()}, ...this.conf}, this.domain)
        this.personal    = new Personal(this.mainNode, this.conf, this.domain)
        // 節日彩蛋使用
        this.search      = new Search(this.mainNode, this.conf, this.lottiePromise)
        // this.search      = new Search(this.mainNode, this.conf)
        this.searchInput = new SearchInput(this.mainNode, this.conf)
        this.notify      = new Notify(this.mainNode, this.conf, this.domain)
        this.logo        = new Logo(this.mainNode, this.conf)
        this.oneWordBtn  = new OneWordBtn(this.mainNode, this.conf)
        // this.shoppingCart = new ShoppingCart(this.mainNode, this.conf, this.pluginMap['shoppingCart'])

        const pluginEventClose = []
        if (this.conf.plugins) {
            for (const plugin of this.conf.plugins) {
                this[plugin.name] = new CustomFeature(this.mainNode, this.conf, plugin)
                pluginEventClose.push(this[plugin.name].eventClose.bind(this[plugin.name]))
                this[plugin.name].on(SystemEvents.CLOSE, [this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
                this[plugin.name].on(SystemEvents.OPEN , [this.personal.eventClose.bind(this.personal), this.search.eventClose.bind(this.search), this.navigation.eventClose.bind(this.navigation), this.notify.eventClose.bind(this.notify), this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
                eventBus.on(SystemEvents.OPEN, plugin.name, this[plugin.name].eventClose.bind(this[plugin.name]))
            }
        }
        
        // 元件之間互相設定監聽 open event，目的是為了在 a 元件開啟時，將其他已開啟的元件關閉
        this.personal.on(SystemEvents.OPEN, [...pluginEventClose, this.search.eventClose.bind(this.search), this.navigation.eventClose.bind(this.navigation), this.notify.eventClose.bind(this.notify), this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        this.search.on(SystemEvents.OPEN, [...pluginEventClose, this.personal.eventClose.bind(this.personal), this.navigation.eventClose.bind(this.navigation), this.notify.eventClose.bind(this.notify), this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        this.navigation.on(SystemEvents.OPEN, [...pluginEventClose, this.personal.eventClose.bind(this.personal), this.search.eventClose.bind(this.search), this.notify.eventClose.bind(this.notify), this.personal.eventCloseLinksBeforeLogin.bind(this.personal), this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        this.notify.on(SystemEvents.OPEN, [...pluginEventClose, this.personal.eventClose.bind(this.personal), this.search.eventClose.bind(this.search), this.navigation.eventClose.bind(this.navigation), this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        // this.navigation.on('moreNavOpen', [this.personal.eventClose.bind(this.personal), this.search.eventClose.bind(this.search), this.notify.eventClose.bind(this.notify), this.personal.eventCloseLinksBeforeLogin.bind(this.personal), this.navigation.eventClose.bind(this.navigation)], true)
        // this.shoppingCart.on(SystemEvents.OPEN, [...pluginEventClose, this.personal.eventClose.bind(this.personal), this.search.eventClose.bind(this.search), this.navigation.eventClose.bind(this.navigation), this.notify.eventClose.bind(this.notify), this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)

        this.personal.on(SystemEvents.CLOSE, [this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        this.search.on(SystemEvents.CLOSE, [this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        this.navigation.on(SystemEvents.CLOSE, [this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        this.notify.on(SystemEvents.CLOSE, [this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)
        // this.shoppingCart.on(SystemEvents.CLOSE, [this.__setFireHeybarOpenOrCloseTimer.bind(this)], true)


        // 設定 search 與 search-input 的系統 keyword handler，主要是用來同步這兩個元件 user 輸入的 keyword
        this.search.on(SystemEvents.KEYWORD, [this.searchInput.syncKeyword.bind(this.searchInput), this.search.syncSuggestKeyword.bind(this.search)], true)
        this.searchInput.on(SystemEvents.KEYWORD, [this.search.syncKeyword.bind(this.search), this.search.syncSuggestKeyword.bind(this.search)], true)
        this.search.on(SystemEvents.SUGGEST, [this.search.syncSuggestion.bind(this.search), this.searchInput.syncSuggestion.bind(this.searchInput)], true)
        this.searchInput.on(SystemEvents.SUGGEST, [this.searchInput.syncSuggestion.bind(this.searchInput), this.search.syncSuggestion.bind(this.search)], true)
        
        this._on(SystemEvents.OPEN, this.__lockBodyScroll.bind(this), true)
        this._on(SystemEvents.CLOSE, this.__unlockBodyScroll.bind(this), true)
        
        if (this.conf.drawWhen === 'domReady') {
            // 等頁面 dom ready 之後才開始畫出 heybar 畫面
            if (document.readyState == 'loading') {
                debug('page loading...wait to replace heybar')
                document.addEventListener('DOMContentLoaded', () => { this.__initDraw(initialData) })
            } else {
                debug('page %s, replace heybar now...', document.readyState)
                this.__initDraw(initialData)
            }
        } else {
            // 不監聽 DOMContentLoaded 事件，改成不斷探測 container 是否存在，存在就馬上畫出來，以加快 HeyBar 出現的速度
            this.__listenToDraw(initialData)
        }
    }

    __replaceContainer() {
        const container = document.querySelector('#heybarContainer')
        debug('container::')
        debug(container)
        if (container && container !== this.mainNode) {
            debug('replace container')
            container.parentNode.replaceChild(this.mainNode, container)
        } else {
            debug('NO replace container')
        }
    }

    /** 等待畫面上的 container node 出現即開始畫 heybar，否則設下 timer 定時去監看 container node 是否已經長出來 */
    __listenToDraw(initialData) {
        debug('start listen to draw...')
        const containerMark = document.querySelector(this.conf.container)
        if (containerMark) {
            debug('container exists. begin to draw...')
            this.__initDraw(initialData, containerMark)
        } else {
            // setTimeout(this.__listenToDraw.bind(this), 200)
            setTimeout(() => {
                this.__listenToDraw(initialData)
            }, 200)
        }
    }

    __initDraw(initialData, containerMark) {
        // debug('this.mainNode::', this.mainNode)
        // debug('containerMark::', containerMark)
        // debug('heybarContainer::', document.querySelector('#heybarContainer'))
        // 建出主畫面 Node 並換掉頁面上預定要畫上去的 node
        if (!containerMark) {
            containerMark = document.querySelector(this.conf.container)
        }
        this.mainNode.innerHTML = mainTemp(this.pluginNames)
        containerMark.parentNode.replaceChild(this.mainNode, containerMark)

        // debug('this.mainNode2::', this.mainNode)
        // debug('containerMark2::', containerMark)
        // debug('heybarContainer2::', document.querySelector('#heybarContainer'))

        // 先畫出 PC 版的 logo (M 版的 logo 在 navigation 內，為了加快 heybar 呈現速度，所以先畫 PC 版)
        if (this.conf.logo) this.logo.draw()

        // 如果 displaySearch 是 always，則換掉 search 區塊
        if (this.conf.displaySearch === 'always') {
            this.search.draw()
            this.searchInput.draw()
        }

        // 如果 displayOneWord 是 always，則呈現 one word button
        if (this.conf.displayOneWord && this.conf.displayOneWord === 'always') {
            this.oneWordBtn.draw()
        }

        // 看看 localStorage 有沒 navigation cache，有的話就先拿出來畫
        if (window.localStorage) {
            const naviCache = window.localStorage.getItem(NAVI_CACHE_NAME)
            if (naviCache) {
                try {
                    debug('--draw cache navigation--')
                    const navigation = JSON.parse(naviCache)
                    this.navigation.draw(navigation)
                } catch (e) {
                    debug('draw naviCache fail. %o', e)
                }
            }

        }

        this.pluginMap.always.forEach(plugin => this[plugin.name].draw())
        if (!this.conf.loginInfo) {
            this.pluginMap.beforeLogin.forEach(plugin => this[plugin.name].draw())
        }
        // if (this.conf.loginInfo) {
        //     this.pluginMap.afterLogin.forEach(plugin => this[plugin.name].draw())
        // } else {
        //     this.pluginMap.beforeLogin.forEach(plugin => this[plugin.name].draw())
        // }


        // 此時才取得初始資料
        initialData.then(initialResult => {
            // debug('this.mainNode3::', this.mainNode)
            // debug('containerMark3::', containerMark)
            // debug('heybarContainer3::', document.querySelector('#heybarContainer'))
            debug('initial result :: %o', initialResult)
            if (initialResult && initialResult.data) {
                this.useN11s     = initialResult.data.use_n11s
                this.productId   = initialResult.data.product_id
                this.productName = this.conf.productName || initialResult.data.product_name
                this.initData    = initialResult.data
                this.domain      = transProductId(this.productId)

                this.notify.productName = this.productName
                this.notify.productId   = this.productId
                this.notify.domain      = this.domain
                this.notify.useN11s     = this.useN11s
                this.navigation.domain  = this.domain
                this.personal.domain    = this.domain

                if (this.conf.displaySearch === 'always') {
                    if (this.conf.searchPlaceholder) {
                        this.search.drawPlaceholder(this.conf.searchPlaceholder, null)
                        this.searchInput.drawPlaceholder(this.conf.searchPlaceholder, null)
                    } else {
                        this.search.drawPlaceholder(this.productName)
                        this.searchInput.drawPlaceholder(this.productName)
                    }

                    if (this.conf.searchValue) {
                        this.search.drawValue(this.conf.searchValue)
                        this.searchInput.drawValue(this.conf.searchValue)
                    }
                }

                // if (this.pluginMap['shoppingCart']) {
                //     this.shoppingCart.draw()
                // }
                
                // 換掉導航區塊
                this.navigation.clean() // 清掉之前的 cache
                this.navigation.draw(this.initData.navigation)
                // 繪製導航區內的 logo
                if (this.conf.logo) this.logo.draw()

                if (window.localStorage) {
                    window.localStorage.setItem(NAVI_CACHE_NAME, JSON.stringify(this.initData.navigation))
                }

                if (this.conf.hideWhileScroll) {
                    this.on(EVENT_INITIATED, () => {
                        let scrollval     = window.scrollY;
                        let hideAnimTimer = null

                        function hideOrShow() {
                            // console.log('===>>>>> hideOrShow :: ' + (scrollval - window.scrollY))
                            let scrollTop = window.scrollY
                            if (this.hideAnimStatus === 'done') {
                                if (Math.abs(scrollval - scrollTop) === 44) return
                                if(scrollval < scrollTop) {
                                    this.hide()
                                } else if (scrollval > scrollTop) {
                                    this.show()
                                }
                            }
                            scrollval = scrollTop <= 0 ? 0 : scrollTop
                        }
                        window.addEventListener('scroll', () => {
                            // 如果目前是因為設定 scrollY 而造成的 scroll 事件，要忽略它
                            if (this.lock4SetScrollY) {
                                this.lock4SetScrollY = false
                                return
                            }
                            // console.log('====>>> scrolling')
                            if (this.hideAnimStatus !== 'done') return
                            if (hideAnimTimer !== null) clearTimeout(hideAnimTimer)
                            hideAnimTimer = setTimeout(hideOrShow.bind(this), 50)  

                            // let scrollTop = window.scrollY
                            // if (this.hideAnimStatus === 'done') {
                            //     // scroll down
                            //     if(scrollval < scrollTop) {
                            //         if (hideAnimTimer !== null) clearTimeout(hideAnimTimer)
                            //         hideAnimTimer = setTimeout(this.hide.bind(this), 50)
                            //     // scroll up
                            //     } else if (scrollval > scrollTop) {
                            //         if (hideAnimTimer !== null) clearTimeout(hideAnimTimer)
                            //         hideAnimTimer = setTimeout(this.show.bind(this), 50)  
                            //     }
                            // }
                            // scrollval = scrollTop <= 0 ? 0 : scrollTop
                        })
                    })
                }
                this.on(EVENT_INITIATED, () => {
                    debug('----> heybar initiate done, call GTM EVENT <----')
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push(
                        { 'event': 'heybar.complete',   }
                    )
                })

                this.on(HeybarEvents.OPEN, () => {
                    debug('I AM OPEN.....', window.scrollY)
                })

                this.on(HeybarEvents.CLOSE, () => {
                    debug('I AM CLOSE.....', window.scrollY)
                })

                // 如果初始就登入的話就呼叫 login 畫出登入後的 heybar，否則只畫出未登入的 personal
                if (this.conf.loginInfo) {
                    this.login(this.conf.loginInfo, getLoginInitialData()).then(() => {
                        this.__replaceContainer()
                        this.fire(EVENT_INITIATED)
                    })
                } else {
                    this.personal.draw()
                    this.personal.drawProductName(this.productName)
                    this.__replaceContainer()
                    this.fire(EVENT_INITIATED)
                }

                // =======> 節日彩蛋使用 START (靜態圖) <========================================================
                // const logoRightNodes = document.querySelectorAll('#festival_logo_right')
                // const logoLeftNodes  = document.querySelectorAll('#festival_logo_left')
                // if (logoRightNodes) {
                //     for (const logoRightNode of logoRightNodes) {
                //         const logoRightImg = document.createElement('img')
                //         logoRightImg.src = `${process.env.SERVER_URL}/lib/2023_DBF_LogoRight.svg`
                //         logoRightNode.appendChild(logoRightImg)
                //     }
                // }
                // if (logoLeftNodes) {
                //     for (const logoLeftNode of logoLeftNodes) {
                //         const logoLeftImg = document.createElement('img')
                //         logoLeftImg.src = `${process.env.SERVER_URL}/lib/2023_DBF_LogoLeft.svg`
                //         logoLeftNode.appendChild(logoLeftImg)
                //     }
                // }
                // =======> 節日彩蛋使用 END (靜態圖) <==========================================================
                // =======> 節日彩蛋使用 START <========================================================
                this.lottiePromise.then(() => {
                    //2022-12-13 因應 cms wordpress 會有兩個相同區塊的問題，改寫成多區塊版
                    const logoRightNodes = document.querySelectorAll('#festival_logo_right')
                    const logoLeftNodes  = document.querySelectorAll('#festival_logo_left')
                    if (logoRightNodes) {
                        for (const logoRightNode of logoRightNodes) {
                            logoRightNode.innerHTML = ''
                            lottie.loadAnimation({
                                container: logoRightNode,
                                renderer: 'svg',
                                loop: true,
                                autoplay: true,
                                path: `${process.env.SERVER_URL}/lib/2022_moon_right_side.json`  // (中秋節)
                                // path: `${process.env.SERVER_URL}/lib/lf30_mvvf58cu.json` // (聖誕節)
                                // path: `${process.env.SERVER_URL}/lib/2024_new_year_right_side.json` // (2024新年)
                            })
                        }
                    }

                    if (logoLeftNodes) {
                        for (const logoLeftNode of logoLeftNodes) {
                            logoLeftNode.innerHTML = ''
                            lottie.loadAnimation({
                                container: logoLeftNode,
                                renderer: 'svg',
                                loop: true,
                                autoplay: true,
                                path: `${process.env.SERVER_URL}/lib/2022_moon_left_side.json`  // (中秋節)
                                // path: `${process.env.SERVER_URL}/lib/lf30_xblft2id.json`  // (聖誕節)
                                // path: `${process.env.SERVER_URL}/lib/2024_new_year_left_side.json`  // (2023新年)
                            })
                        }
                    }
                    // 2022-12-13 因應 cms wordpress 會有兩個相同區塊的問題，改寫成多區塊版，所以以下註解 (2022-12-13 start)
                    // const logoRightNode = document.querySelector('#festival_logo_right')
                    // const logoLeftNode  =  document.querySelector('#festival_logo_left')
                    // if (logoRightNode) logoRightNode.innerHTML = ''
                    // if (logoLeftNode) logoLeftNode.innerHTML = ''
                    
                    // lottie.loadAnimation({
                    //     container: logoRightNode,
                    //     renderer: 'svg',
                    //     loop: true,
                    //     autoplay: true,
                    //     // path: `${process.env.SERVER_URL}/lib/2022_moon_right_side.json`  // (中秋節)
                    //     path: `${process.env.SERVER_URL}/lib/lf30_mvvf58cu.json` // (聖誕節)
                    // })

                    // lottie.loadAnimation({
                    //     container: logoLeftNode,
                    //     renderer: 'svg',
                    //     loop: true,
                    //     autoplay: true,
                    //     // path: `${process.env.SERVER_URL}/lib/2022_moon_left_side.json`  // (中秋節)
                    //     path: `${process.env.SERVER_URL}/lib/lf30_xblft2id.json`  // (聖誕節)
                    // })
                    // (2022-12-13 end)

                //     /*
                //     this.on(EVENT_INITIATED, () => {
                //         console.log('<------- GOGOGOG ------->')
                //         console.log(document.querySelector('#heybarNotifyRing'))
                //         // top: -87px left: - 10px;
                //         const ring = lottie.loadAnimation({
                //             container: document.querySelector('#heybarNotifyRing'),
                //             renderer: 'svg',
                //             loop: false,
                //             autoplay: false,
                //             name: 'heybarNotifyRingAnim',
                //             path: `${process.env.SERVER_URL}/lib/lf30_xk4wdbaf.json`
                //         })
                //         ring.addEventListener('DOMLoaded', () => {
                //             console.log("0000==>")
                //             console.log()
                //             const ringNode = document.querySelector('#heybarNotifyRing')
                //             console.log("000----0000", ringNode)
                //             ringNode.setAttribute('style', "background-size: 0px !important")
                //             const ringSvg = document.querySelector('#heybarNotifyRing svg')
                //             if (ringSvg) {
                //                 // ringSvg.className = barCss['xms_ring']
                //                 // ringSvg.classList.add(barCss['xms_ring'])
                //                 ringSvg.setAttribute('class', barCss['xms_ring'])
                //                 console.log(barCss['xms_ring'])
                //                 // ringSvg.style.position = "absolute"
                //                 // ringSvg.style.width = "184%"
                //                 // ringSvg.style.height = "184%"
                //                 // ringSvg.style.top = "-10px"
                //                 // ringSvg.style.left = "-10px"
                //             }
                //             ring.play()
                //         })

                //         ring.addEventListener('complete', () => {
                //             ring.destroy('heybarNotifyRingAnim')
                //             const ringNode = document.querySelector('#heybarNotifyRing')
                //             ringNode.setAttribute('style', "background-size: 24px !important")
                //         })
                //     })
                //     */
                })
                // =======> 節日彩蛋使用 END <========================================================
            } else {
                throw new Error('Initial HeyBar Fail. can not get initial data...')
            }
        }).catch(reason => {
            throw new Error(`Initial Heybar Fail. reason is ${reason}`)
        })
    }

    login(loginInfo, loginData) {
        this.pluginMap.afterLogin.forEach(plugin => this[plugin.name].draw())
        this.pluginMap.beforeLogin.forEach(plugin => this[plugin.name].restore())
        return new Promise((resolve, reject) => {
            // 尚未登入且 loginInfo 不為 null 才往下進行登入後畫面的繪製
            if (!this.isLogin && loginInfo) {
                // 如果 loginData 沒有傳進來且要使用通知的話，那一開始先抓 loginData 以加快 heybar 的顯示速度
                if (!loginData && this.useN11s) loginData = getLoginInitialData()

                // 若有群組且為分群模式，且從 groups 中對出當前群組的群組名稱
                if (loginInfo.notification && loginInfo.notification.groupId && loginInfo.notification.groups) {
                    for (const group of loginInfo.notification.groups) {
                        if (group.id === loginInfo.notification.groupId) {
                            loginInfo.notification.groupName = group.name
                            break
                        }
                    }
                }

            
                // 畫出或換成已登入的個人區塊
                this.personal.clean()
                this.personal.draw(loginInfo, this.initData.official_services)
                this.personal.drawProductName(this.productName, loginInfo.notification ? loginInfo.notification.groupName : null)

                // 若 search 類型為「登入後才能 search」，那在登入之後要把 search 區塊加上去
                if (this.conf.displaySearch === 'afterLogin') {
                    this.search.draw()
                    this.searchInput.draw()
                    if (this.conf.searchPlaceholder) {
                        this.search.drawPlaceholder(this.conf.searchPlaceholder, null)
                        this.searchInput.drawPlaceholder(this.conf.searchPlaceholder, null)
                    } else {
                        this.search.drawPlaceholder(this.productName)
                        this.searchInput.drawPlaceholder(this.productName)
                    }

                    if (this.conf.searchValue) {
                        this.search.drawValue(this.conf.searchValue)
                        this.searchInput.drawValue(this.conf.searchValue)
                    }
                }

                // 若 one word button 類型為「換入後才能放上去」，那在登入之後要把 one word button 加上去
                if (this.conf.displayOneWord && this.conf.displayOneWord === 'afterLogin') {
                    this.oneWordBtn.draw()
                }

                // 看 cookie 內有沒要一開始就開啟通知的 cookie，有的話設定要開啟通知還是公告
                let openWhichNotify = null
                const openNotifyCookie = getCookie(Notify.OPEN_NOTIFY_KEY)
                // if (openNotifyCookie && openNotifyCookie.startsWith(`${this.productId}#`)) {
                if (openNotifyCookie) {
                    openWhichNotify = openNotifyCookie.split('#')[1]
                }

                // 要使用通知的話，把通知加上去
                if (this.useN11s) {
                    // 2023-08-14 如果 useN11s 的模式是"使用通知但只限其他產品"的話，由於不顯示自己產品的通知，因此把 group 相關的參數都清成 null。
                    //            這樣可以讓後續的程式(notification-helper, notify) 不用考慮 group 的問題。
                    if (this.useN11s === UseN11s.ENABLE_ONLY_OTHERS && loginInfo.notification) {
                        loginInfo.notification.groupId   = null
                        loginInfo.notification.groupType = null
                        loginInfo.notification.groups    = null
                    }

                    loginData.then(loginInitialData => {
                        debug('login initial data :: %o', loginInitialData)
                        if (loginInitialData && loginInitialData.data) {
                            const crossProducts = isNil(loginInfo.notification) || isNil(loginInfo.notification.crossProducts) || loginInfo.notification.crossProducts
                            this.notify.groupName = loginInfo.notification && loginInfo.notification.groupName ? loginInfo.notification.groupName : null
                            this.notify.groupDesc = loginInfo.notification.groupDesc || '其他公司'
                            this.notify.useHeadshot = isNil(loginInfo.notification.useHeadshot) ? false : loginInfo.notification.useHeadshot
                            this.notify.defaultHeadshot = loginInfo.notification.defaultHeadshot
                            this.notify.draw()
                            // this.notify.draw(loginInfo.notification && loginInfo.notification.groupName ? loginInfo.notification.groupName : null)
                            // this.notify.drawProductName(this.productName, loginInfo.notification ? loginInfo.notification.groupName: null)
                            
                            // 若 crossProducts == false (不跨產品)，則傳給 n11sHelper 的 products 就只留當前的產品即可
                            let products = []
                            if (crossProducts) {
                                products = loginInitialData.data.products
                            } else {
                                if (loginInitialData.data.products) {
                                    for (let p of loginInitialData.data.products) {
                                        if (p.id === this.productId) {
                                            products.push(p)
                                            break
                                        }
                                    }
                                }
                            }
                            // 若 products 的內容是空的，則填入當前產品的資訊
                            if (products.length === 0) products.push({id: this.productId, name: this.productName, home_link: '#', product_id: this.productId})
                            
                            // 初始化 Notification
                            this.n11sHelper = new NotificationHelper(
                                this.notify,
                                Object.assign({}, {
                                    pid          : loginInitialData.data.hashPid,
                                    productId    : this.productId,
                                    products     : products, // loginInitialData.data.products,
                                    crossProducts: crossProducts,
                                    useN11s      : this.useN11s,
                                    // n11sSettings: loginInitialData.data.n11sSettings,
                                }, loginInfo.notification ? {...loginInfo.notification} : {})
                            )

                            /* 2021-10-08 先馬掉專心做畫面 */
                            this.n11sHelper.initiate().then(() => {
                                // 全部都初始完才完成
                                // this.notify.drawDone() // 改放到 n11sHelper.initiate() 執行 (for loading display)
                                
                                // 如果要一開始就開啟通知
                                if (openWhichNotify) {
                                    this.notify.eventOpen()
                                    this.notify.changeTab(openWhichNotify)
                                    // 開啟完把 cookie 刪除
                                    deleteCookie(Notify.OPEN_NOTIFY_KEY, process.env.COOKIE_DOMAIN, '/')
                                }
                                
                                this.fire(EVENT_LOGIN)
                                resolve()

                            })

                        } else {
                            error('Draw Notify Fail, login initial data got from API is  NULL')
                            this.fire(EVENT_LOGIN)
                            resolve()
                        }

                    }).catch(reason => {
                        error('Draw Notify occur error! reason is %o', reason)
                        this.fire(EVENT_LOGIN)
                        resolve()
                    })

                } else {
                    // 看看是否有屬於此 product 的要開啟通知的 cookie，有的話刪除掉(因為沒有啟用通知)
                    if (!openWhichNotify) {
                        deleteCookie(Notify.OPEN_NOTIFY_KEY, process.env.COOKIE_DOMAIN, '/')
                    }
                    this.fire(EVENT_LOGIN)
                    resolve()
                }

                this.isLogin = true
            } else {
                if (!loginInfo) error('Login FAIL! because login info is null')
                resolve()
            }
        })
    }

    logout() {
        if (this.isLogin) {
            // 將已登入的個人資訊區塊換成未登入
            this.personal.clean()
            this.personal.draw()
            this.personal.drawProductName(this.productName)

            // 如果 search 的類型是「登入後才能search」，那在登出時要把 search 區塊拿掉
            if (this.conf.displaySearch === 'afterLogin') {
                this.search.clean()
                this.searchInput.clean()
            }

            // 若 one word button 類型為「換入後才能放上去」，那在登出時要把 one word button 拿掉
            if (this.conf.displayOneWord && this.conf.displayOneWord === 'afterLogin') {
                this.oneWordBtn.clean()
            }

            // 把通知區塊清除
            if (this.useN11s) {
                this.notify.clean()
                if (this.n11sHelper) this.n11sHelper.destroy()
            }

            this.pluginMap.afterLogin.forEach(plugin => this[plugin.name].restore())
            this.pluginMap.beforeLogin.forEach(plugin => this[plugin.name].draw())

            this.isLogin = false
            this.fire(EVENT_LOGOUT)
        }
    }

    on(event, callback) {
        if (event.startsWith('notification/')) {
            // Notification.on(event.replace, callback)
            if (event === Notification.EVENT.EVENT_ADD_BUTTETIN || event === Notification.EVENT.EVENT_ADD_NOTIFICATION || event === Notification.EVENT.EVENT_CLICK_NOTIFICATION ||
                event === Notification.EVENT.EVENT_CLICK_GROUP_ITEM || event === Notification.EVENT.EVENT_CLICK_PRODUCT_ITEM || event === Notification.EVENT.EVENT_NO_UNREAD_ALL ||
                event === Notification.EVENT.EVENT_NO_UNREAD_THIS || event === Notification.EVENT.EVENT_NO_NOTIFICATION_UNREAD_THIS) {
                Notification.on(event, callback)
            } else {
                let pureEvent = event.replace(/^notification\//, '')
                Notification.on(pureEvent, callback)
                this.notify.on(pureEvent, callback)
            }
        } else if (event.startsWith('personal/')) {
            this.personal.on(event.replace(/^personal\//, ''), callback)
        } else if (event.startsWith('oneword/')) {
            this.oneWordBtn.on(event.replace(/^oneword\//, ''), callback)
        } else if (event.startsWith('navigation/')) {
            this.navigation.on(event.replace(/^navigation\//, ''), callback)
        } else {
            this._on(event, callback)
        }
    }

    off (event, callback) {
        if (event.startsWith('notification/')) {
            // Notification.off(event, callback)
            if (event === Notification.EVENT.EVENT_ADD_BUTTETIN || event === Notification.EVENT.EVENT_ADD_NOTIFICATION || event === Notification.EVENT.EVENT_CLICK_NOTIFICATION ||
                event === Notification.EVENT.EVENT_CLICK_GROUP_ITEM || event === Notification.EVENT.EVENT_CLICK_PRODUCT_ITEM || event === Notification.EVENT.EVENT_NO_UNREAD_ALL || 
                event === Notification.EVENT.EVENT_NO_UNREAD_THIS || event === Notification.EVENT.EVENT_NO_NOTIFICATION_UNREAD_THIS) {
                Notification.off(event, callback)
            } else {
                let pureEvent = event.replace(/^notification\//, '')
                Notification.off(pureEvent, callback)
                this.notify.off(pureEvent, callback)
            }
        } else if (event.startsWith('personal/')) {
            this.personal.off(event.replace(/^personal\//, ''), callback)
        } else if (event.startsWith('oneword/')) {
            this.oneWordBtn.off(event.replace(/^oneword\//, ''), callback)
        } else {
            this._off(event, callback)
        }
    }

    closeMenu() {
        this.notify.eventClose()
        this.search.eventClose()
        this.navigation.eventClose()
        this.personal.eventClose()

        for (const pluginName of this.pluginNames) {
            this[pluginName].eventClose()
        }
    }

    replacePersonalHeadshot(headshot) {
        this.personal.replaceHeadshot(headshot)
    }

    replacePersonalName(name) {
        this.personal.replaceName(name)
    }

    refreshES(es) {
        setEnhanceSecurity(es)
    }

    refreshHotkeys(hotkeys) {
        this.conf.hotkeys = hotkeys
        this.search.refreshHotkeys(hotkeys)
    }

    hide() {
        // console.log("---DOWN")
        // console.log("---> STATUS:::", this.hideAnimStatus)

        if (this.hideAnimStatus === 'hiding') return
        const barTopNode = document.querySelector(`.${barCss.barTop}`)
        if (barTopNode.offsetTop === -44) return
        if (!this.hideHeybarAnim1) {
            const container  = document.querySelector('#heybarContainer')
            this.hideHeybarAnim1 = barTopNode.animate([{top: '0px'}, {top: '-22px', offset: 0.5}, {top: '-44px'}], {fill: 'forwards', duration: 300})
            this.hideHeybarAnim2 = container.animate([{height: '44px'}, {height: '22px', offset: 0.5}, {height: '0px'}], {fill: 'forwards', duration: 300})
            this.hideHeybarAnim1.pause()
            this.hideHeybarAnim2.pause()
            this.hideHeybarAnim1.onfinish = () => { this.hideAnimStatus = 'done' }
            this.hideHeybarAnim1.play()
            this.hideHeybarAnim2.play()

            // barTopNode.addEventListener('animationiteration', () => {
            //     console.log('AAAAAA')
            // })
            // barTopNode.onanimationiteration = () => { console.log('bbbb')}
        } else {
            this.hideHeybarAnim1.pause()
            this.hideHeybarAnim2.pause()
            this.hideHeybarAnim1.reverse()
            this.hideHeybarAnim2.reverse()
        }
        
        this.hideAnimStatus = 'hiding'

        // if (this.hideAnimStatus !== 'done') return
        // const container  = document.querySelector('#heybarContainer')
        // const barTopNode = document.querySelector(`.${barCss.barTop}`)

        // 
        // this.hideHeybarAnim1 = barTopNode.animate([{top: '0px'}, {top: '-22px', offset: 0.5}, {top: '-44px'}], {fill: 'forwards', duration: 300})
        // this.hideHeybarAnim2 = container.animate([{height: '44px'}, {height: '22px', offset: 0.5}, {height: '0px'}], {fill: 'forwards', duration: 300})
        // this.hideHeybarAnim1.onfinish = () => { this.hideAnimStatus = 'done' }
        // this.hideAnimStatus = 'hiding'
    }

    show() {
        // console.log("---UPPP")

        if (this.hideAnimStatus === 'showing') return
        const barTopNode = document.querySelector(`.${barCss.barTop}`)
        if (barTopNode.offsetTop === 0) return
        if (this.hideHeybarAnim1) {
            this.hideHeybarAnim1.pause()
            this.hideHeybarAnim2.pause()
            this.hideHeybarAnim1.reverse()
            this.hideHeybarAnim2.reverse()
            this.hideAnimStatus = 'showing'
        }

        // if (this.hideAnimStatus !== 'done') return
        // const barTopNode = document.querySelector(`.${barCss.barTop}`)
        // if (barTopNode.offsetTop === 0) return
        // if (this.hideHeybarAnim1) {
        //     this.hideHeybarAnim1.reverse()
        //     this.hideHeybarAnim2.reverse()
        //     this.hideAnimStatus = 'showing'
        // }
    }

    redrawNavigation(operateMode, replaceUtm, params) {
        this.navigation.clean()
        this.navigation.draw(this.initData.navigation, operateMode, replaceUtm, params)
    }

    showMode() {
        return modeHelper.currentMode(this.mainNode)
    }

    __fireHeybarOpenEvent() {
        // if (this.openOrCloseEvent === HeybarEvents.OPEN)
        // this.openOrCloseEvent = HeybarEvents.OPEN
        this.__setFireHeybarOpenOrCloseTimer()
    }

    __fireHeybarCloseEvent() {
        // if (this.openOrCloseEvent === HeybarEvents.CLOSE) return
        // this.openOrCloseEvent= HeybarEvents.CLOSE
        this.__setFireHeybarOpenOrCloseTimer()
    }
    __setFireHeybarOpenOrCloseTimer() {
        if (!this.fireOpenOrCloseEventTimer) {
            this.fireOpenOrCloseEventTimer = setTimeout(() => {
                let isOpen = !!(this.navigation.isOpen | this.search.isOpen | this.notify.isOpen | this.personal.isOpen)

                for (const pluginName of this.pluginNames) {
                    if (this[pluginName].isOpen) this._isOpenPluginName_ = pluginName

                    isOpen = !!(isOpen | this[pluginName].isOpen)
                }

                // 如果現在要發出 OPEN 事件，但當下已經是 OPEN 狀態就不發，CLOSE 也是同樣的規則
                if (isOpen && !this.isOpen) {
                    this.emit(SystemEvents.OPEN)
                    this.fire(HeybarEvents.OPEN)
                    
                } else if (!isOpen && this.isOpen) {
                    this.emit(SystemEvents.CLOSE)
                    this.fire(HeybarEvents.CLOSE)
                    
                }

                this.isOpen = isOpen
                this.fireOpenOrCloseEventTimer = null
                this._isOpenPluginName_ = null
                
            }, 50)
        }
    }

    __lockBodyScroll() {
        if (modeHelper.isMobileMode(this.mainNode)) {
            const scrollWidth = window.innerWidth - document.body.clientWidth
            if (scrollWidth > 0) {
                const barTopNode = document.querySelector(`.${barCss.barTop}`)
                if (barTopNode) barTopNode.style.width = `calc(100% - ${scrollWidth}px)`
            }
            this.bodyScrollY  = window.scrollY
            this.bodyPosition = document.body.style.position
            document.body.style.position = 'fixed'
            this.isBodyScrollLock = true
        }
    }

    __unlockBodyScroll() {
        if (this.isBodyScrollLock) {
            document.body.style.position = this.bodyPosition
            this.lock4SetScrollY = true
            window.scrollTo({ top: this.bodyScrollY, behavior: 'instant'})
            this.bodyScrollY  = null
            this.bodyPosition = null
            this.isBodyScrollLock = false
            const barTopNode = document.querySelector(`.${barCss.barTop}`)
            if (barTopNode) barTopNode.style.width = '100%'
        }
    }
}


// 避掉 eventMixin 與 HeyBar 有相同的 on/off function
const cloneEventMixin = Object.assign({}, eventMixin())
cloneEventMixin._on = cloneEventMixin.on
cloneEventMixin._off = cloneEventMixin.off
delete cloneEventMixin.on
delete cloneEventMixin.off
Object.assign(HeyBar.prototype, cloneEventMixin)
export default HeyBar