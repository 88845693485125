import BaseComponent            from '@/components/base-component'
import { navigationTemp }       from '@/templates/navigation-temp'
import { stringToDom }          from '@/utils/tools'
import { domMixin, eventMixin } from '@/mixins'
import * as SystemEvents        from '@/constants/system-event'
import * as HeybarEvents        from '@/constants/heybar-event'
import barCss                   from '~css/bar.scss'
// import iconArrowButtonRight     from '~img/icon-arrow-button-right.png'

const { error } = require('@/utils/logger')(__filePath, __fileName, '#FF5511')
const NAVIGATION_ID = 'heybarNavigation'
const MORE_TYPE_SMALL = 'SMALL'
const MORE_TYPE_LARGE = 'LARGE'

class Navigation extends BaseComponent {
    static MARK_TAG = 'navigation'

    constructor(mainNode, config, domain) {
        super(mainNode, config, ['prodNaviM', 'beforeDraw', 'twoColumnsNavi'])
        this.isOpen = false  // 導航選單是否已開啟
        this.domain = domain
        this.eventClose4BodyTemp = this.eventClose4Body.bind(this)
        this.inNaviMenu = false
        this.closeMenuTimer = null
        this.moreType = MORE_TYPE_SMALL
    }

    _getMoreListNode(anchor) {
        anchor = anchor ? anchor : this.mainNode
        // return this.findByTag(anchor, 'div[data-navi-menu=moreMenu]')
        return this.findByClassName(anchor, 'heybarNaviMoreList')
    }

    _appendUtmToLink(link, replaceUtm) {
        if (link != null && link !== '' && link !== '#' && link.indexOf('utm_campaign') < 0) {
            link = `${link}${link.indexOf('?') >= 0 ? '&' : '?'}utm_campaign=${this.domain}`
        }

        if (replaceUtm && link) {
            const utmRegexps   = []
            const replaceWords = []
            for (let utmKey in replaceUtm) {
                utmRegexps.push(new RegExp(`${utmKey}=[^&\n]+`, 'g'))
                replaceWords.push(`${utmKey}=${replaceUtm[utmKey]}`)
            }

            for (let i = 0; i < utmRegexps.length; i++) {
                link = link.replaceAll(utmRegexps[i], replaceWords[i])
            }
        }

        return link
    }

    _generateNode(navigations, operateMode, replaceUtm, params) {
        // 先將基本的 template 載成 dom
        const naviNode = stringToDom(navigationTemp())

        if (navigations && navigations.length > 0) {
            const rootNaviNode   = document.createDocumentFragment() // 產生 PC 版 Navigation(root)
            const leafNaviNode   = document.createDocumentFragment() // 產生 PC 版 Navigation(leaf)
            const leafNavi4MNode = document.createDocumentFragment() // 產生 M 版 Navigation(leaf)
            const leafNaviSmallMenuNode  = document.createDocumentFragment() // 產生 PC 版 20220815 小視窗版的 Navigation(leaf)
            const leafNaviCustom4MNode   = document.createElement('div') // 放置自訂導航區塊(M 版)
            const leafNaviOfficial4MNode = document.createElement('div') // 放置官方導航區塊 (M 版)
            const leafNavi4MList = [] // 產生出來的 M 版 Navigation Item 先放此處等待排序
            this.moreType = MORE_TYPE_SMALL
            for (const n of navigations) {
                if (['MENU', 'SMALL_MENU', 'SMALL_VERTICAL_MENU'].includes(n.operate_mode)) {
                    this.moreType = MORE_TYPE_LARGE
                    break
                }
            }

            // 產品自定義的導航項(只出現在 M 版)
            if (this.prodNaviM && this.prodNaviM.length > 0) {
                for (const item of this.prodNaviM) {
                    const leafNaviItem4MNode = document.createElement('div')
                    leafNaviItem4MNode.className = barCss['smNav2-item']
                    leafNaviItem4MNode.dataset.link = item.link
                    leafNaviItem4MNode.dataset.text = item.text
                    // leafNaviItem4MNode.dataset.gtmParam    = this.domain
                    // leafNaviItem4MNode.dataset.gtmSubParam = `導流menu-${item.text}`
                    leafNaviItem4MNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                    leafNaviItem4MNode.dataset.gtmValue    = `${item.text}-${item.text}`

                    leafNaviItem4MNode.dataset.operateMode = 'NEW_LINK'
                    leafNaviItem4MNode.innerHTML = item.text

                    leafNaviCustom4MNode.appendChild(leafNaviItem4MNode)
                }
                leafNavi4MNode.appendChild(leafNaviCustom4MNode)
                leafNaviCustom4MNode.classList.add(barCss['smNav2-custom'])
            }
            leafNavi4MNode.appendChild(leafNaviOfficial4MNode)

            const leafNaviOfficalTitle4MNode = document.createElement('div')
            leafNaviOfficalTitle4MNode.classList.add(barCss['smNav2-title'])
            leafNaviOfficalTitle4MNode.innerHTML = this.config.moreServiceText || '104更多服務'
            leafNaviOfficial4MNode.appendChild(leafNaviOfficalTitle4MNode)
            

            // "更多"選項
            const moreListNode = document.createElement('div')
            if (this.moreType === MORE_TYPE_SMALL) {
                moreListNode.classList.add(barCss['overflow-nav-list'])
                moreListNode.classList.add(barCss.triangle)
                moreListNode.classList.add('heybarNaviMoreList')
                moreListNode.style.pointerEvents = 'auto'
            } else {
                moreListNode.classList.add(barCss['nav-PC'])
                moreListNode.classList.add('heybarNaviMoreList')
                moreListNode.dataset.naviMenu = 'moreMenu'
                moreListNode.style.display    = 'none'
                leafNaviNode.appendChild(moreListNode)
            }


            for (let i = 0; i < navigations.length; i++) {
                const navigation = navigations[i]
                navigation.link = this._appendUtmToLink(navigation.link, replaceUtm)
                if (operateMode && ['MENU', 'SMALL_MENU', 'SMALL_VERTICAL_MENU'].includes(navigation.operate_mode)) {
                    navigation.operate_mode = operateMode
                }
                // ***************************************************************************************************************************//
                // PC 導航項目繪製
                // ***************************************************************************************************************************//
                // 如果此導航項目沒有設定 display_mode 或其 display_mode 是 ALL 或 PC 才進行 PC 版繪製
                if (!navigation.display_mode || ['ALL', 'PC'].includes(navigation.display_mode.toUpperCase())) {
                    const menuKey                        = `navigationMenu${i}`
                    const itemKey                        = `navigationItem${i}`
                    const rootNaviItemNode               = document.createElement('div')
                    rootNaviItemNode.classList.add(barCss['leftNav-title'])
                    // const rootNaviItemNode               = document.createElement('li')
                    // rootNaviItemNode.classList.add(barCss['overflow-nav-title'])
                    rootNaviItemNode.classList.add('heybarRootNaviItem')
                    // rootNaviItemNode.dataset.gtmParam    = this.domain
                    // rootNaviItemNode.dataset.gtmSubParam = `導流menu-${navigation.text}`
                    // 第一層連結型態與選單型態的 gtm 設定要分開來
                    if (!['MENU', 'SMALL_MENU', 'SMALL_VERTICAL_MENU'].includes(navigation.operate_mode)) {
                        rootNaviItemNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                        rootNaviItemNode.dataset.gtmValue    = `${navigation.text}-${navigation.text}`
                    } else {
                        rootNaviItemNode.dataset.gtmWhitebar = 'browsing_diversion-group'
                        rootNaviItemNode.dataset.gtmValue    = navigation.text
                    }
                    rootNaviItemNode.dataset.operateMode = navigation.operate_mode
                    rootNaviItemNode.dataset.link        = navigation.link
                    rootNaviItemNode.dataset.text        = navigation.text
                    rootNaviItemNode.dataset.menuKey     = menuKey
                    rootNaviItemNode.dataset.itemKey     = itemKey
                    rootNaviItemNode.style.pointerEvents = 'auto'

                    // 20220815 新版小選單改成類似的選單的呈現方式，因此不用在 rootNavItem 裏放小選單了
                    // 20221014 再度恢復，不過另改名為 SMALL_VERTICAL_MENU
                    if (navigation.operate_mode !== 'SMALL_VERTICAL_MENU') {
                        rootNaviItemNode.innerHTML = navigation.text
                    } else {
                        const spanNode = document.createElement('span')
                        spanNode.classList.add(barCss['overflow-nav-title'])
                        spanNode.innerHTML = navigation.text
                        rootNaviItemNode.appendChild(spanNode)
                        rootNaviItemNode.classList.add(barCss['priority-triangle'])
                    }
                    // rootNaviItemNode.innerHTML = navigation.text  // 20220815 新版小選單改成類似的選單的呈現方式，因此不用在 rootNavItem 裏放小選單了
                    
                    rootNaviNode.appendChild(rootNaviItemNode)

                    // 將 root 導航項目抄一份到 more 列表中 (第一顆導航不會折行，所以就不計入了)。
                    // 2022-01-19 將第一領顆導航項目也列入折行裏
                    // if (i > 0) {
                    // 2022-06-17 more 改成大menu的樣式
                    let moreListItemCatNode = null
                    if (this.moreType === MORE_TYPE_SMALL) {
                        const moreListItemNode               = document.createElement('div')
                        moreListItemNode.id                  = `heybarMoreListItem_${itemKey}`
                        moreListItemNode.dataset.link        = navigation.link
                        moreListItemNode.dataset.text        = navigation.text
                        // moreListItemNode.dataset.operateMode = navigation.operate_mode === 'MENU' ? 'IGNORE' : navigation.operate_mode
                        moreListItemNode.dataset.operateMode = navigation.operate_mode
                        moreListItemNode.style.display       = 'none'
                        moreListItemNode.innerHTML           = navigation.text
                        moreListNode.appendChild(moreListItemNode)
                    } else {
                        moreListItemCatNode                = document.createElement('div')
                        moreListItemCatNode.className      = barCss['nav-list']
                        moreListItemCatNode.id             = `heybarMoreListItem_${itemKey}`
                        // const moreListItemCatLabelNode     = document.createElement('div')
                        // moreListItemCatLabelNode.className = barCss['nav-label']
                        // moreListItemCatNode.appendChild(moreListItemCatLabelNode)
                        moreListNode.appendChild(moreListItemCatNode)

                        // moreListItemCatLabelNode.innerHTML = navigation.text
                        // 第一層的導航繪製在 more list 中，如果不是選單類的，就繪製成 nav-item(有連結的)
                        if (!['MENU', 'SMALL_MENU', 'SMALL_VERTICAL_MENU'].includes(navigation.operate_mode)) {
                            // moreListItemCatLabelNode.innerHTML      = '&nbsp;'
                            const moreListItemCatItem               = document.createElement('div')
                            moreListItemCatItem.className           = barCss['nav-item']
                            moreListItemCatItem.innerHTML           = `<span>${navigation.text}</span>`
                            moreListItemCatItem.dataset.link        = navigation.link
                            moreListItemCatItem.dataset.text        = navigation.text
                            // moreListItemCatItem.dataset.gtmParam    = this.domain
                            // moreListItemCatItem.dataset.gtmSubParam = `導流menu-${navigation.text}`
                            moreListItemCatItem.dataset.gtmwhitebar = 'diversion_diversion-item'
                            moreListItemCatItem.dataset.gtmValue    = `更多-${navigation.text}`
                            moreListItemCatNode.appendChild(moreListItemCatItem)
                        } else {
                            const moreListItemCatLabelNode     = document.createElement('div')
                            moreListItemCatLabelNode.className = barCss['nav-label']
                            moreListItemCatNode.appendChild(moreListItemCatLabelNode)
                            moreListItemCatLabelNode.innerHTML = navigation.text
                        }
                    }
                    // ---- 以上第一層繪製完成，以下繪製子層(如果該導航項目有子層的話) ---------------- //

                    // 若此導航項目的操作模式是 MENU 且底下有 category，則繪製子層
                    if (navigation.operate_mode === 'MENU' && (navigation.categories && navigation.categories.length > 0)
                    ) {
                        rootNaviItemNode.classList.add(barCss['overflow-nav-title'])
                        const leafNaviGroupNode            = document.createElement('div')
                        leafNaviGroupNode.className        = barCss['nav-PC']
                        leafNaviGroupNode.dataset.naviMenu = menuKey
                        leafNaviGroupNode.dataset.operateMode = navigation.operate_mode
                        leafNaviGroupNode.style.display    = 'none'
                        leafNaviNode.appendChild(leafNaviGroupNode)

                        // 繪製導航的類目與類目項目
                        if (navigation.categories) {
                            for (const category of navigation.categories) {
                                const leafNaviCatNode = document.createElement('div')
                                leafNaviCatNode.className = barCss['nav-list']
                                const leafNaviLabelNode = document.createElement('div')
                                leafNaviLabelNode.className = barCss['nav-label']
                                leafNaviLabelNode.innerHTML = category.text != null && category.text !== '' ? category.text : '&nbsp;'
                                leafNaviCatNode.appendChild(leafNaviLabelNode)
                                leafNaviGroupNode.appendChild(leafNaviCatNode)

                                // 繪製第三層(如果有的話)
                                if (category.items) {
                                    for (const item of category.items) {
                                        if (item.display_mode && ['ALL', 'PC'].includes(item.display_mode.toUpperCase())) {
                                            item.link = this._appendUtmToLink(item.link, replaceUtm)
                                            const leafNaviItemNode = document.createElement('div')
                                            leafNaviItemNode.className = barCss['nav-item']
                                            leafNaviItemNode.innerHTML = `<span>${item.text}</span>`
                                            leafNaviItemNode.dataset.link = item.link
                                            leafNaviItemNode.dataset.text = item.text
                                            // leafNaviItemNode.dataset.gtmParam    = this.domain
                                            // leafNaviItemNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                            leafNaviItemNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                                            leafNaviItemNode.dataset.gtmValue    = `${navigation.text}-${item.text}`
                                            leafNaviItemNode.dataset.operateMode = item.operate_mode

                                            leafNaviCatNode.appendChild(leafNaviItemNode)
                                            

                                            if (this.moreType === MORE_TYPE_LARGE) {
                                                const moreListItemCatItemNode = document.createElement('div')
                                                moreListItemCatItemNode.className = barCss['nav-item']
                                                moreListItemCatItemNode.innerHTML = `<span>${item.text}</span>`
                                                moreListItemCatItemNode.dataset.link = item.link
                                                moreListItemCatItemNode.dataset.text = item.text
                                                // moreListItemCatItemNode.dataset.gtmParam    = this.domain
                                                // moreListItemCatItemNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                                moreListItemCatItemNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                                                moreListItemCatItemNode.dataset.gtmValue    = `更多-${item.text}`
                                                moreListItemCatNode.appendChild(moreListItemCatItemNode)
                                            }
                                        }
                                    }
                                }
                                if (this.moreType === MORE_TYPE_LARGE) {
                                    // 每個 category 加上一個 space item 來隔開
                                    const moreListItemCatSpaceItemNode = document.createElement('div')
                                    moreListItemCatSpaceItemNode.className = barCss['nav-item']
                                    moreListItemCatSpaceItemNode.innerHTML = '<span>&nbsp;</span>'
                                    moreListItemCatNode.appendChild(moreListItemCatSpaceItemNode)
                                }
                            }                            
                        }
                    
                    // 2022-06-16 新增加 SMALL_MENU 模式 [第一層導航項目直接接 item 層(第三層)，沒有 category 層(第二層)]
                    } else if (navigation.operate_mode === 'SMALL_MENU' && (navigation.items && navigation.items.length > 0)) {
                        // const leftNaviMenuNode  = document.createElement('div')
                        // leftNaviMenuNode.classList.add(barCss['overflow-nav-list'])
                        // leftNaviMenuNode.classList.add(barCss.triangle)
                        // leftNaviMenuNode.style.pointerEvents = 'auto'
                        // leftNaviMenuNode.dataset.om          = navigation.operate_mode
                        // leftNaviMenuNode.dataset.naviMenu    = menuKey
                        // rootNaviItemNode.appendChild(leftNaviMenuNode)
                        rootNaviItemNode.classList.add(barCss['overflow-nav-title'])
                        // rootNaviItemNode.classList.add(barCss['priority-triangle'])
                        const leftNaviMenuNode = document.createElement('div')
                        leftNaviMenuNode.classList.add(barCss.megaMenu)
                        leftNaviMenuNode.classList.add('heybarNaviSmallMenu')
                        leftNaviMenuNode.style.pointerEvents = 'auto'
                        leftNaviMenuNode.style.display    = 'none'
                        leftNaviMenuNode.dataset.naviMenu = menuKey
                        leftNaviMenuNode.dataset.om       = navigation.operate_mode
                        leafNaviSmallMenuNode.appendChild(leftNaviMenuNode)

                        // 繪製 root 導航項的直屬 item
                        // if (navigation.items) {
                        for (const item of navigation.items) {
                            item.link = this._appendUtmToLink(item.link, replaceUtm)
                            if (item.display_mode && ['ALL', 'PC'].includes(item.display_mode.toUpperCase())) {
                                const leafNaviItemNode               = document.createElement('div')
                                leafNaviItemNode.dataset.link        = item.link
                                leafNaviItemNode.dataset.text        = item.text
                                // leafNaviItemNode.dataset.gtmParam    = this.domain
                                // leafNaviItemNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                leafNaviItemNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                                leafNaviItemNode.dataset.gtmValue    = `${navigation.text}-${item.text}`
                                // leafNaviItemNode.innerHTML           = item.text
                                // leftNaviMenuNode.appendChild(leafNaviItemNode)
                                leafNaviItemNode.classList.add(barCss['megaMenu-item'])
                                if (this.twoColumnsNavi || (params && params.twoColumnsNavi)) leafNaviItemNode.classList.add(barCss['megaMenu-item-2cols'])
                                if (item.picture_link) {
                                    const imgDivNode = document.createElement('div')
                                    const imgNode    = document.createElement('img')
                                    imgDivNode.classList.add(barCss['megaMenu-icon'])
                                    imgNode.src = item.picture_link
                                    imgDivNode.appendChild(imgNode)
                                    leafNaviItemNode.appendChild(imgDivNode)
                                }
                                const itemInfoNode  = document.createElement('div')
                                const itemTextNode  = document.createElement('div')
                                const itemIntroNode = document.createElement('div')
                                itemInfoNode.classList.add(barCss['megaMenu-info'])
                                itemTextNode.classList.add(barCss['megaMenu-title'])
                                itemIntroNode.classList.add(barCss['megaMenu-text'])
                                itemTextNode.innerHTML = item.text
                                itemIntroNode.innerHTML = item.intro ? item.intro : '&nbsp;'
                                itemInfoNode.appendChild(itemTextNode)
                                itemInfoNode.appendChild(itemIntroNode)
                                leafNaviItemNode.appendChild(itemInfoNode)

                                leftNaviMenuNode.appendChild(leafNaviItemNode)

                                if (this.moreType === MORE_TYPE_LARGE) {
                                    const moreListItemCatItem = document.createElement('div')
                                    moreListItemCatItem.className = barCss['nav-item']
                                    moreListItemCatItem.innerHTML = `<span>${item.text}</span>`
                                    moreListItemCatItem.dataset.link = item.link
                                    moreListItemCatItem.dataset.text = item.text
                                    // moreListItemCatItem.dataset.gtmParam    = this.domain
                                    // moreListItemCatItem.dataset.gtmSubParam = `導流menu-${item.text}`
                                    moreListItemCatItem.dataset.gtmWhitebar = 'diversion_diversion-item'
                                    moreListItemCatItem.dataset.gtmValue    = `更多-${item.text}`
                                    moreListItemCatNode.appendChild(moreListItemCatItem)
                                }
                            }
                        }                            
                        // }
                    // 2022-10-14 新增加 SMALL_VERTICAL_MENU 模式
                    } else if (navigation.operate_mode === 'SMALL_VERTICAL_MENU' && (navigation.items && navigation.items.length > 0)) {
                        
                        const leftNaviMenuNode = document.createElement('div')
                        leftNaviMenuNode.classList.add(barCss.megaMenuB)
                        leftNaviMenuNode.style.pointerEvents = 'auto'
                        leftNaviMenuNode.style.display    = 'none'
                        leftNaviMenuNode.dataset.naviMenu = menuKey
                        leftNaviMenuNode.dataset.om       = navigation.operate_mode
                        rootNaviItemNode.appendChild(leftNaviMenuNode)

                        // 繪製 root 導航項的直屬 item
                        // if (navigation.items) {
                        for (const item of navigation.items) {
                            item.link = this._appendUtmToLink(item.link, replaceUtm)
                            if (item.display_mode && ['ALL', 'PC'].includes(item.display_mode.toUpperCase())) {
                                const leafNaviItemNode               = document.createElement('div')
                                leafNaviItemNode.dataset.link        = item.link
                                leafNaviItemNode.dataset.text        = item.text
                                // leafNaviItemNode.dataset.gtmParam    = this.domain
                                // leafNaviItemNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                leafNaviItemNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                                leafNaviItemNode.dataset.gtmValue    = `${navigation.text}-${item.text}`
                                leafNaviItemNode.classList.add(barCss['megaMenuB-item'])
                                if (item.picture_link) {
                                    const imgDivNode = document.createElement('div')
                                    const imgNode    = document.createElement('img')
                                    imgDivNode.classList.add(barCss['megaMenuB-icon'])
                                    imgNode.src = item.picture_link
                                    imgNode.style.width = '24px'
                                    imgDivNode.appendChild(imgNode)
                                    leafNaviItemNode.appendChild(imgDivNode)
                                }
                                const itemInfoNode  = document.createElement('div')
                                const itemTitleNode = document.createElement('div')
                                const itemTextNode  = document.createElement('div')
                                itemInfoNode.classList.add(barCss['megaMenuB-info'])
                                itemTitleNode.classList.add(barCss['megaMenuB-title'])
                                itemTextNode.classList.add(barCss['megaMenuB-text'])
                                if (params && params.alwaysShow) {
                                    itemTitleNode.style.transform = 'translateY(-50%)'
                                    itemTextNode.style.transform = 'translate(0%)'
                                    itemTextNode.style.opacity = 1
                                }
                                
                                itemTitleNode.innerHTML = item.text
                                itemTextNode.innerHTML = item.intro ? item.intro : '&nbsp;'
                                itemInfoNode.appendChild(itemTitleNode)
                                itemInfoNode.appendChild(itemTextNode)
                                leafNaviItemNode.appendChild(itemInfoNode)

                                leftNaviMenuNode.appendChild(leafNaviItemNode)

                                if (this.moreType === MORE_TYPE_LARGE) {
                                    const moreListItemCatItem = document.createElement('div')
                                    moreListItemCatItem.className = barCss['nav-item']
                                    moreListItemCatItem.innerHTML = `<span>${item.text}</span>`
                                    moreListItemCatItem.dataset.link = item.link
                                    moreListItemCatItem.dataset.text = item.text
                                    // moreListItemCatItem.dataset.gtmParam    = this.domain
                                    // moreListItemCatItem.dataset.gtmSubParam = `導流menu-${item.text}`
                                    moreListItemCatItem.dataset.gtmWhitebar = 'diversion_diversion-item'
                                    moreListItemCatItem.dataset.gtmValue    = `更多-${item.text}`
                                    moreListItemCatNode.appendChild(moreListItemCatItem)
                                }
                            }
                        }                            
                        // }
                    }
                }

                // ***************************************************************************************************************************//
                // MOBILE 導航項目繪製
                // ***************************************************************************************************************************//
                // 如果此導航項目沒有設定 display_mode 或其 display_mode 為 ALL 或 MOBILE 則繪製 M 版
                if (!navigation.display_mode || ['ALL', 'MOBILE'].includes(navigation.display_mode.toUpperCase())) {
                    const leafNaviBlock4MNode     = document.createElement('div')
                    leafNaviBlock4MNode.m_order   = navigation.m_order  // 因為要做排序，所以先加到陣列中
                    leafNavi4MList.push(leafNaviBlock4MNode)

                    // if (navigation.categories && navigation.categories.length > 0) {
                    //     const leafNaviBlockTitle4MNode = document.createElement('div')
                    //     leafNaviBlockTitle4MNode.className = barCss['smNav2-navi']
                    //     leafNaviBlockTitle4MNode.innerHTML = navigation.text
                    //     leafNaviBlock4MNode.appendChild(leafNaviBlockTitle4MNode)
                    // }
                    if ((navigation.categories && navigation.categories.length > 0) || (navigation.items && navigation.items.length > 0)) {
                        const leafNaviBlockTitle4MNode = document.createElement('div')
                        leafNaviBlockTitle4MNode.className = barCss['smNav2-navi']
                        leafNaviBlockTitle4MNode.innerHTML = navigation.text
                        leafNaviBlock4MNode.appendChild(leafNaviBlockTitle4MNode)

                        let allItems      = []
                        let categoryItems = []

                        if (navigation.categories && navigation.categories.length > 0) {
                            for (const category of navigation.categories) {
                                allItems = allItems.concat(category.items)
                            }
                            categoryItems = categoryItems.concat(navigation.categories)
                        }

                        if (navigation.items && navigation.items.length > 0) {
                            allItems = allItems.concat(navigation.items)
                            categoryItems.push({items: navigation.items})
                        }
                        
                        // 繪製水平呈現的導航
                        if (navigation.m_orient === 'HORIZONTAL') {
                            const leafNaviItemList4MNode     = document.createElement('div')
                            leafNaviItemList4MNode.className = barCss['explore-list']
                            leafNaviBlock4MNode.appendChild(leafNaviItemList4MNode)

                            // for (const item of navigation.items) {
                            for (const item of allItems) {
                                if (item.display_mode && ['ALL', 'MOBILE'].includes(item.display_mode.toUpperCase())) {
                                    item.link = this._appendUtmToLink(item.link, replaceUtm)
                                    const leafNaviItem4MNode = document.createElement('div')
                                    leafNaviItem4MNode.className = barCss['explore-item']
                                    leafNaviItem4MNode.dataset.link = item.link
                                    leafNaviItem4MNode.dataset.text = item.text
                                    // leafNaviItem4MNode.dataset.gtmParam    = this.domain
                                    // leafNaviItem4MNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                    leafNaviItem4MNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                                    leafNaviItem4MNode.dataset.gtmValue    = `${navigation.text}-${item.text}`
                                    leafNaviItem4MNode.dataset.operateMode = item.operate_mode

                                    if (item.picture_link) {
                                        const itemImg4MNode = document.createElement('img')
                                        itemImg4MNode.src   = item.picture_link
                                        leafNaviItem4MNode.appendChild(itemImg4MNode)
                                    }
                                    const itemText4MNode = document.createElement('p')
                                    itemText4MNode.innerHTML = item.text
                                    leafNaviItem4MNode.appendChild(itemText4MNode)

                                    leafNaviItemList4MNode.appendChild(leafNaviItem4MNode)
                                }
                            }
                            
                        // 繪製垂直呈現的導航
                        } else if (navigation.m_orient === 'VERTICAL') {
                            for (const category of categoryItems) {
                                if (typeof(category.text) !== 'undefined') {
                                    const leafNaviItemLabel4MNode     = document.createElement('div')
                                    leafNaviItemLabel4MNode.className = barCss['smNav2-label']
                                    leafNaviItemLabel4MNode.innerHTML = category.text
                                    leafNaviBlock4MNode.appendChild(leafNaviItemLabel4MNode)
                                }
                            
                                for (const item of category.items) {
                                    if (item.display_mode && ['ALL', 'MOBILE'].includes(item.display_mode.toUpperCase())) {
                                        item.link = this._appendUtmToLink(item.link, replaceUtm)
                                        const leafNaviItem4MNode = document.createElement('div')
                                        leafNaviItem4MNode.className = barCss['smNav2-item']
                                        leafNaviItem4MNode.dataset.link = item.link
                                        leafNaviItem4MNode.dataset.text = item.text
                                        // leafNaviItem4MNode.dataset.gtmParam    = this.domain
                                        // leafNaviItem4MNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                        leafNaviItem4MNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                                        leafNaviItem4MNode.dataset.gtmValue    = `${navigation.text}-${item.text}`
                                        leafNaviItem4MNode.dataset.operateMode = item.operate_mode
                                        leafNaviBlock4MNode.appendChild(leafNaviItem4MNode)

                                        if (item.picture_link) {
                                            const itemImg4MNode  = document.createElement('img')
                                            itemImg4MNode.className = barCss['smNav2-v-img']
                                            itemImg4MNode.src    = item.picture_link
                                            leafNaviItem4MNode.appendChild(itemImg4MNode)

                                            const itemSpan4MNode = document.createElement('span')
                                            itemSpan4MNode.innerHTML = item.text
                                            leafNaviItem4MNode.appendChild(itemSpan4MNode)
                                        } else {
                                            leafNaviItem4MNode.innerHTML = item.text
                                        }
                                    }
                                }
                            }
                        }

                    // 非選單式的根導航，將相關連結參數放上去
                    } else {
                        const leafNaviItem4MNode = document.createElement('div')
                        leafNaviItem4MNode.className           = barCss['smNav2-item']
                        leafNaviItem4MNode.innerHTML           = navigation.text
                        leafNaviItem4MNode.style.cursor        = 'pointer'
                        leafNaviItem4MNode.dataset.text        = navigation.text
                        leafNaviItem4MNode.dataset.link        = navigation.link
                        // leafNaviItem4MNode.dataset.gtmParam    = this.domain
                        // leafNaviItem4MNode.dataset.gtmSubParam = `導流menu-${navigation.text}`
                        leafNaviItem4MNode.dataset.gtmWhitebar = 'diversion_diversion-item'
                        leafNaviItem4MNode.dataset.gtmValue    = `${navigation.text}-${navigation.text}`
                        leafNaviItem4MNode.dataset.operateMode = navigation.operate_mode
                        leafNaviBlock4MNode.appendChild(leafNaviItem4MNode)
                    }

                    // M 版排序並將排序後的 node 加到 M 版畫面中
                    leafNavi4MList.sort((a, b) => a.m_order - b.m_order).forEach(item => leafNaviOfficial4MNode.appendChild(item))

                    /*
                    const leafNaviCat4MNode     = document.createElement('div')
                    leafNaviCat4MNode.className = barCss.smNav
                    leafNaviCat4MNode.m_order   = navigation.m_order  // 因為要做排序，所以先加到陣列中
                    leafNavi4MList.push(leafNaviCat4MNode)
                    const leafNaviLabel4MNode     = document.createElement('div')
                    leafNaviLabel4MNode.className = barCss['smNav-title']
                    leafNaviLabel4MNode.innerHTML = navigation.text
                    leafNaviCat4MNode.appendChild(leafNaviLabel4MNode)

                    // 此導航下有 categories 或 items 則繪製第二層
                    if (navigation.categories && navigation.categories.length > 0) {
                        // 水平呈現不繪製 navigation 的 items 項目，且不顯示出 category 的名稱
                        if (navigation.m_orient === 'HORIZONTAL') {
                            if (navigation.categories) {
                                const leafNaviItemList4MNode     = document.createElement('div')
                                leafNaviItemList4MNode.className = barCss['explore-list']
                                leafNaviCat4MNode.appendChild(leafNaviItemList4MNode)
                                for (const category of navigation.categories) {
                                    if (category.items) {
                                        for (const item of category.items) {
                                            if (item.display_mode && ['ALL', 'MOBILE'].includes(item.display_mode.toUpperCase())) {
                                                item.link = this._appendUtmToLink(item.link)
                                                const leafNaviItem4MNode = document.createElement('div')
                                                leafNaviItem4MNode.className = barCss['explore-item']
                                                leafNaviItem4MNode.dataset.link = item.link
                                                leafNaviItem4MNode.dataset.text = item.text
                                                leafNaviItem4MNode.dataset.gtmParam    = this.domain
                                                leafNaviItem4MNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                                leafNaviItem4MNode.dataset.operateMode = item.operate_mode

                                                if (item.picture_link) {
                                                    const itemImg4MNode = document.createElement('img')
                                                    itemImg4MNode.src   = item.picture_link
                                                    leafNaviItem4MNode.appendChild(itemImg4MNode)
                                                }
                                                const itemText4MNode = document.createElement('p')
                                                itemText4MNode.innerHTML = item.text
                                                leafNaviItem4MNode.appendChild(itemText4MNode)

                                                leafNaviItemList4MNode.appendChild(leafNaviItem4MNode)
                                            }
                                        }
                                    }
                                }
                            }
                        // 繪製垂直呈現的導航
                        } else if (navigation.m_orient === 'VERTICAL') {
                            if (navigation.categories) {
                                for (const category of navigation.categories) {
                                    const leafNaviItemList4MNode     = document.createElement('div')
                                    leafNaviItemList4MNode.className = barCss['smNav-list']
                                    leafNaviCat4MNode.appendChild(leafNaviItemList4MNode)
                                    if (category.text != null && category.text !== '') {
                                        const leafNaviItemLabel4MNode     = document.createElement('div')
                                        leafNaviItemLabel4MNode.className = barCss['smNav-label']
                                        leafNaviItemLabel4MNode.innerHTML = category.text
                                        leafNaviItemList4MNode.appendChild(leafNaviItemLabel4MNode)
                                    }
                                    if (category.items) {
                                        for (const item of category.items) {
                                            if (item.display_mode && ['ALL', 'MOBILE'].includes(item.display_mode.toUpperCase())) {
                                                item.link = this._appendUtmToLink(item.link)
                                                const leafNaviItem4MNode = document.createElement('div')
                                                leafNaviItem4MNode.className = barCss['smNav-item']
                                                leafNaviItem4MNode.innerHTML = `<span>${item.text}</span>`
                                                leafNaviItem4MNode.dataset.link = item.link
                                                leafNaviItem4MNode.dataset.text = item.text
                                                leafNaviItem4MNode.dataset.gtmParam    = this.domain
                                                leafNaviItem4MNode.dataset.gtmSubParam = `導流menu-${item.text}`
                                                leafNaviItem4MNode.dataset.operateMode = item.operate_mode
                                                leafNaviItemList4MNode.appendChild(leafNaviItem4MNode)
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    // 非選單式的根導航，將相關連結參數放上去
                    } else {
                        leafNaviLabel4MNode.style.cursor        = 'pointer'
                        leafNaviLabel4MNode.dataset.text        = navigation.text
                        leafNaviLabel4MNode.dataset.link        = navigation.link
                        leafNaviLabel4MNode.dataset.gtmParam    = this.domain
                        leafNaviLabel4MNode.dataset.gtmSubParam = `導流menu-${navigation.text}`
                        leafNaviLabel4MNode.dataset.operateMode = navigation.operate_mode
                    }

                    // M 版排序並將排序後的 node 加到 M 版畫面中
                    leafNavi4MList.sort((a, b) => a.m_order - b.m_order).forEach(item => leafNavi4MNode.appendChild(item))
                    */
                }
            }

            const bottomSpaceNode = document.createElement('div')
            bottomSpaceNode.style.height = '48px'
            bottomSpaceNode.innerHTML = '&nbsp;'
            leafNavi4MNode.appendChild(bottomSpaceNode)

            
            const moreRootNaviItemNode = document.createElement('div')
            moreRootNaviItemNode.classList.add(barCss['leftNav-title'])
            moreRootNaviItemNode.classList.add('overflow-nav')
            moreRootNaviItemNode.style.pointerEvents = 'auto'
            moreRootNaviItemNode.dataset.operateMode = 'IGNORE'

            const spanNode = document.createElement('span')
            spanNode.classList.add(barCss['overflow-nav-title'])
            spanNode.innerHTML = '更多'
            spanNode.dataset.gtmWhitebar = 'browsing_diversion-group'
            spanNode.dataset.gtmValue    = '更多'

            // const moreNode = document.createElement('div')
            // moreNode.classList.add(barCss['overflow-nav-list'])
            // moreNode.classList.add(barCss.triangle)s
            // moreNode.innerHTML = "<div>aaa</div><div>bbb</div>"
            
            moreRootNaviItemNode.appendChild(spanNode)
            if (this.moreType === MORE_TYPE_SMALL) {
                moreRootNaviItemNode.appendChild(moreListNode)
            }
            rootNaviNode.appendChild(moreRootNaviItemNode)


            // 將挖空的地方填上
            const leafMNaviMarkNode = this.findByTag(naviNode, 'leaf-m-nav')
            const leafNaviMarkNode  = this.findByTag(naviNode, 'leaf-nav')
            const rootNaviMarkNode  = this.findByTag(naviNode, 'root-nav')
            const leafNaviSmallMenuMarkNode = this.findByTag(naviNode, 'leaf-nav-small-menu')

            if (rootNaviMarkNode) rootNaviMarkNode.parentNode.replaceChild(rootNaviNode, rootNaviMarkNode)
            if (leafNaviMarkNode) leafNaviMarkNode.parentNode.replaceChild(leafNaviNode, leafNaviMarkNode)
            if (leafMNaviMarkNode) leafMNaviMarkNode.parentNode.replaceChild(leafNavi4MNode, leafMNaviMarkNode)
            if (leafNaviSmallMenuMarkNode) leafNaviSmallMenuMarkNode.parentNode.replaceChild(leafNaviSmallMenuNode, leafNaviSmallMenuMarkNode)

        }

        return naviNode
        
    }

    /** 綁定事件 */
    _bindEvent(container) {
        this._bindOpenMenuEvent(container)  // 綁開啟選單的事件
        this._bindCloseMenuEvent(container) // 綁關閉選單的事件
        this._bindClickNaviItemEvent(container) // 綁按下導航的事件
        this._bindOpenMoreMenuEvent(container)

        // 避免開啟的子 menu 在點擊其上空白處而關閉(因全域的 eventClose 的關係)
        const naviMenuContainerNode = this.findByClassName(container, barCss['nav-content'])
        if (naviMenuContainerNode) { naviMenuContainerNode.onclick = evt => { evt.stopPropagation() }}
        const naviSmallMenuNodes = this.findAllByClassName(container, 'heybarNaviSmallMenu')
        if (naviSmallMenuNodes) { 
            naviSmallMenuNodes.forEach(n => {
                n.onclick = evt => { evt.stopPropagation()}
            })
        }

        window.removeEventListener('click', this.eventClose4BodyTemp)
        window.addEventListener('click', this.eventClose4BodyTemp)

    }

    _bindOpenMoreMenuEvent(container) {
        const moreItemNode = this.findByClassName(container, 'overflow-nav')
        
        if (moreItemNode) {
            moreItemNode.onmouseenter = evt => {
                evt.stopPropagation()
                if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
                if (this.isOpen) {
                    // 導航第二層開啟的狀態下，有可能點擊另一個有第二層的導航，此時要先關後開
                    // if (!moreItemNode.classList.contains(barCss.open)) {
                        this.eventClose(evt)
                        this.eventMoreClose(evt)
                        this.eventMoreOpen(evt)
                        
                    // } else {
                    //     this.eventClose(evt)
                    //     this.eventMoreClose(evt)
                    // }
                    
                } else {
                    this.eventMoreOpen(evt)
                }
                
            }
            moreItemNode.onmouseleave = evt => {
                if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
                if (!this.inNaviMenu) this.closeMenuTimer = setTimeout(() => {
                    this.eventClose(evt)
                    this.eventMoreClose(evt)
                }, 200)   
            }
        }
    }

    /** 綁定開啟導航 menu 的 click 事件 */
    _bindOpenMenuEvent(container) {
        // 點擊有 menu 的導航開啟 menu
        // const rootWithMenuNodes = container.querySelectorAll('div:is([data-operate-mode=MENU], [data-operate-mode=SMALL_MENU])')
        const rootWithMenuNodes = container.querySelectorAll('div.heybarRootNaviItem[data-operate-mode=MENU], div.heybarRootNaviItem[data-operate-mode=SMALL_MENU], div.heybarRootNaviItem[data-operate-mode=SMALL_VERTICAL_MENU]')
        rootWithMenuNodes.forEach(node => {
            node.onmouseenter = evt => {
                evt.stopPropagation()
                if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
                if (this.isOpen) {
                    // 導航第二層開啟的狀態下，有可能點擊另一個有第二層的導航，此時要先關後開
                    // if (!node.classList.contains(barCss.open)) {
                        this.eventClose(evt)
                        this.eventMoreClose(evt)
                        this.eventOpen(evt)    
                    // } else {
                    //     this.eventClose(evt)
                    //     this.eventMoreClose(evt)
                    // }
                    
                } else {
                    this.eventOpen(evt)
                }
            }

            node.onmouseleave = evt => {
                if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
                if (!this.inNaviMenu) this.closeMenuTimer = setTimeout(() => {
                    this.eventClose(evt)
                    this.eventMoreClose(evt)
                }, 200)
                
                
            }
        })

        const leafNaviGroupNodes = container.querySelector(`.${barCss['nav-content']}`)
        
        leafNaviGroupNodes.onmouseenter = evt => {
            if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
            evt.stopPropagation()
            if (this.isOpen) {
                this.inNaviMenu = true
            }
        }
        
        leafNaviGroupNodes.onmouseleave = evt => {
            if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
            this.inNaviMenu = false

            const openNaviMenuNode = document.querySelector(`.${barCss.barIcon}.${barCss.barIcon_menu}`)
            const style            = window.getComputedStyle(openNaviMenuNode, null)
            // 如果是 pc 版面則 leave 時關閉視窗，M 版則不用
            if (style.display === 'none') this.eventClose(evt)
        }

        const naviSmallMenus = container.querySelectorAll('.heybarNaviSmallMenu')
        if (naviSmallMenus) {
            naviSmallMenus.forEach(n => {
                n.onmouseenter = evt => {
                    if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
                    evt.stopPropagation()
                    if (this.isOpen) {
                        this.inNaviMenu = true
                    }
                }
                
                n.onmouseleave = evt => {
                    if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
                    this.inNaviMenu = false
                    this.eventClose(evt)
                }
            })
        }

        // small menu 在 rootWithMenuNodes 的結構內，屬於 rootWithMenuNodes 的一部份，因此不需要特別設定 mouseenter 與 mouseleave
        // const naviSmallMenus = container.querySelectorAll('.__small_menu__')
        // naviSmallMenus.forEach(n => {
        //     n.onmouseenter = evt => {
        //         console.log('!!! ININ')
        //         if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
        //         evt.stopPropagation()
        //         if (this.isOpen) {
        //             this.inNaviMenu = true
        //         }
        //     }
        //     n.onmouseleave = evt => {   
        //         console.log('!!! OUTOUT')
        //         if (this.closeMenuTimer) clearTimeout(this.closeMenuTimer)
        //         this.inNaviMenu = false
        //         this.eventClose(evt)
        //     }
        // })
        

        
        // 抓 M 版漢堡線 NODE
        const openNaviMenuNode = container.querySelector(`.${barCss.barIcon}.${barCss.barIcon_menu}`)
        // 若 M 版漢堡線存在
        if (openNaviMenuNode) {
            const naviNode = container.querySelector(`.${barCss['leftNav-title']}`)
            openNaviMenuNode.onclick = evt => {
                evt.stopPropagation()
                // 模擬按下第一個有 menu 的 navigation。
                if (rootWithMenuNodes && rootWithMenuNodes.length > 0) {
                    rootWithMenuNodes[0].dispatchEvent(new MouseEvent('mouseenter'))
                // 若沒有找到任一個有 menu 的 navigation，則抓 navigation node 的第一個來放 open 這個 class
                } else {
                    if (naviNode) {
                        // 若不加以下這兩行(設定 logoNode open)，會造成與 "擬按下第一個有 menu 的 navigation" 所開啟的選單會有 top 44px 的落差
                        const logoNode = this.findByClassName(this.mainNode, barCss['bar_logo--short'])
                        if (logoNode) logoNode.classList.add(barCss['bar_logo--open'])

                        naviNode.classList.add(barCss.open)
                        this.isOpen = true
                    }
                }
            }
        }
    }

    /** 綁定關閉導航 menu 的 click 事件 */
    _bindCloseMenuEvent(container) {
        const closeNaviMenuNode = container.querySelector(`.${barCss.iconClose}`)
        if (closeNaviMenuNode) closeNaviMenuNode.onclick = this.eventClose.bind(this)
    }

    /** 綁定所有導航 item 的 click 事件 */
    _bindClickNaviItemEvent(container) {
        const rootNaviItemNodes = container.querySelectorAll(`.${barCss['leftNav-title']}`)
        const rootNaviItem4MNodes = container.querySelectorAll(`.${barCss['smNav2-title']}[data-link]`)
        const menuNaviItemNodes = container.querySelectorAll(`.${barCss['nav-item']}`)
        const menuNaviItem4HMNodes = container.querySelectorAll(`.${barCss['explore-item']}`)
        const menuNaviItem4VMNodes = container.querySelectorAll(`.${barCss['smNav2-item']}`)
        const menuNaviItem4SmallMenuNodes = container.querySelectorAll(`.${barCss['megaMenu-item']}`)
        const menuNaviItem4SmallVerticalMenuNodes = container.querySelectorAll(`.${barCss['megaMenuB-item']}`)

        rootNaviItemNodes.forEach(n => {
            if (n.dataset.operateMode !== 'MENU') n.onclick=this.eventClickItem.bind(this)
        })
        rootNaviItem4MNodes.forEach(n => {n.onclick=this.eventClickItem.bind(this)})
        menuNaviItemNodes.forEach(n => {n.onclick=this.eventClickItem.bind(this)})
        menuNaviItem4HMNodes.forEach(n => {n.onclick=this.eventClickItem.bind(this)})
        menuNaviItem4VMNodes.forEach(n => {n.onclick=this.eventClickItem.bind(this)})
        console.log(menuNaviItem4SmallMenuNodes)
        menuNaviItem4SmallMenuNodes.forEach(n => {n.onclick=this.eventClickItem.bind(this)})
        menuNaviItem4SmallVerticalMenuNodes.forEach(n => {n.onclick=this.eventClickItem.bind(this)})
        
    }

    eventClose4Body(evt) {
        // 設在 body 上的 close event 為了避免影響(遮斷)到頁面上其他的 click 事件，因此不能設定 stopPropagation
        this.eventClose(evt, false)
        this.eventMoreClose(evt, false)
    }

    eventClose(evt, stopPropagation=true) {
        if (evt && stopPropagation) evt.stopPropagation()
        if (!this.isOpen) return // 已是關閉的情況下就不用再執行關閉了
        this.isOpen = false
        this.mainNode.querySelectorAll(`.${barCss['leftNav-title']}`).forEach(n => {
            n.classList.remove(barCss.open)
            n.classList.remove(barCss['priority-triangle'])
        })
        this.mainNode.querySelectorAll('div[data-navi-menu], div[megaMenuB]').forEach(n => {
            n.style.display = 'none'
            if (n.dataset.operateMode === 'SMALL_MENU') n.classList.remove(barCss['show'])
        })

        const logoNode = this.findByClassName(this.mainNode, barCss['bar_logo--short'])
        if (logoNode) logoNode.classList.remove(barCss['bar_logo--open'])

        this.emit(SystemEvents.CLOSE)
        this.fire(HeybarEvents.CLOSE)
    }
    eventOpen(evt) {
        if (evt) evt.stopPropagation()
        if (this.isOpen) return // 已是開啟的情況下就不用再執行了
        this.isOpen = true

        const rootWithMenuNodes = this.mainNode.querySelectorAll('div[data-operate-mode=MENU], div[data-operate-mode=SMALL_MENU], div[data-operate-mode=SMALL_VERTICAL_MENU]')
        const menuNodes         = this.mainNode.querySelectorAll('div[data-navi-menu], div[megaMenuB]')

        rootWithMenuNodes.forEach(n => {
            n.classList.remove(barCss.open)
            n.classList.remove(barCss['priority-triangle'])
        })
        
        menuNodes.forEach(n => {
            if (n.dataset.naviMenu === evt.target.dataset.menuKey) {
                n.style.display = null
                // if (n.dataset.om === 'SMALL_VERTICAL_MENU') n.classList.add(barCss['show'])
                if (n.dataset.om === 'SMALL_MENU') evt.target.classList.add(barCss['priority-triangle'])

            } else {
                n.style.display = 'none'
                // if (n.dataset.om === 'SMALL_MENU') n.classList.remove(barCss['show'])
            }
        })

        evt.target.classList.add(barCss.open)

        const logoNode = this.findByClassName(this.mainNode, barCss['bar_logo--short'])
        if (logoNode) logoNode.classList.add(barCss['bar_logo--open'])

        // this.fire(`${this.SYSTEM_EVENT_PREFIX}open`)
        // this.fire('open')
        this.emit(SystemEvents.OPEN)
        this.fire(HeybarEvents.OPEN)
    }
    eventMoreOpen(evt) {
        if (evt) evt.stopPropagation()

        // const moreListNode = this.findByClassName(this.mainNode, barCss['overflow-nav-list'])
        // const moreListNode = this.findByClassName(this.mainNode, 'heybarNaviMoreList')
        const moreListNode = this._getMoreListNode(this.mainNode) // this.mainNode.querySelector('div[data-navi-menu=moreMenu]')
        if (!moreListNode) return
        this.isOpen = true
        if (this.moreType === MORE_TYPE_SMALL) {
            moreListNode.style.visibility = 'visible'
            moreListNode.style.opacity = 1
            moreListNode.classList.add(barCss['show'])
        } else {
            moreListNode.style.display = null
        }
        
        evt.target.classList.add(barCss.open)
    }
    eventMoreClose(evt, stopPropagation=true) {
        if (evt && stopPropagation) evt.stopPropagation()
 
        const moreItemNode = this.findByClassName(this.mainNode, 'overflow-nav')
        const moreListNode = this._getMoreListNode(this.mainNode) // this.mainNode.querySelector('div[data-navi-menu=moreMenu]')
        // const moreListNode = this.findByClassName(this.mainNode, 'heybarNaviMoreList')
        // const moreListNode = this.findByClassName(this.mainNode, barCss['overflow-nav-list'])

        if (!moreItemNode) return
        this.isOpen = false
        if (this.moreType === MORE_TYPE_SMALL) {
            moreListNode.style.visibility = 'hidden'
            moreListNode.style.opacity = 0
            moreListNode.classList.remove(barCss['show'])
        } else {
            moreListNode.style.display = 'none'
        }
        moreItemNode.classList.remove(barCss.open)
    }
    eventClickItem(evt) {
        evt.stopPropagation()

        // 註：從第二層選單來的 item click 要吃 evt.currentTarget 才正確(吃外層), evt.target 不正確
        //    但從 more(更多) 下拉選單來的 item click 卻是吃 evt.target (吃內層) 而不是 evt.currentTarget
        //    因為兩者剛好反過來，因此乾脆改成再往下判斷有沒 dataset.link 屬性
        let itemNode
        if (evt.target && evt.target.dataset.link) {
            itemNode = evt.target
        } else {
            itemNode = evt.currentTarget
        }
        
        // 如果 navi item 的操作模式是 MENU 則返回不繼續(因為已經有針對 MENU 做處理了)
        if (itemNode.dataset.operateMode && itemNode.dataset.operateMode === 'MENU') {
            return
        }

        if (!itemNode.dataset.operateMode || ['NEW_LINK', 'LINK'].includes(itemNode.dataset.operateMode)) {
            let doLinkFlag = true
            if (this.config.naviAction && typeof this.config.naviAction === 'function') {
                evt.preventDefault = () => { doLinkFlag = false}
                this.config.naviAction(evt, itemNode.dataset.text, itemNode.dataset.link)
            }

            if (doLinkFlag) {
                if (!itemNode.dataset.operateMode || itemNode.dataset.operateMode === 'NEW_LINK') {
                    window.open(itemNode.dataset.link)
                } else {
                    location.href = itemNode.dataset.link
                }
            }
        }
    }
    
    clean() {        
        this.cleanHandlers()
        this.isOpen = false

        const hambargNode = this.findByClassName(this.mainNode, barCss.barIcon, `.${barCss.barIcon_menu}`)
        if (hambargNode) hambargNode.parentNode.removeChild(hambargNode)
        const navigationNode = this.findById(this.mainNode, 'heybarNavigation')
        if (navigationNode) navigationNode.parentNode.replaceChild(document.createElement('navigation'), navigationNode)

        window.removeEventListener('click', this.eventCloseTemp)
    }

    draw(...params) {
        super.draw(...params)
        // console.log("<<<<<====GOGOGOOGOGGOGOGO=====>>>>")
        
        const naviListNode         = this.findByClassName(this.mainNode, barCss.leftNav)
        const moreItemNode         = this.findByClassName(this.mainNode, 'overflow-nav')
        // const moreListNode         = this.findByClassName(this.mainNode, barCss['overflow-nav-list'])
        // const moreListNode         = this.findByClassName(this.mainNode, 'heybarNaviMoreList')
        const moreListNode         = this._getMoreListNode(this.mainNode)
        const rootNaviItemNodeList = this.findAllByClassName(this.mainNode, barCss.leftNav, ` .heybarRootNaviItem`)
        const naviTop              = rootNaviItemNodeList.length > 0 ? rootNaviItemNodeList[0].offsetTop : 0
        
        if (this.naviObserver) this.naviObserver.disconnect()
        if (this.moreObserver) this.moreObserver.disconnect()
        
        if (this.config.hideNaviM) {
            const openNaviMenuNode = this.findByClassName(this.mainNode, barCss.barIcon, `.${barCss.barIcon_menu}`) // container.querySelector(`.${barCss.barIcon}.${barCss.barIcon_menu}`)
            const naviRootNode     = this.findById(this.mainNode, 'heybarNavigation')
            if (openNaviMenuNode) openNaviMenuNode.style.display = 'none'
            if (naviRootNode) naviRootNode.style.marginLeft = '15px'
        }

        if (moreItemNode) moreItemNode.style.display = 'none'
        // naviListNode.style.backgroundColor = 'red'
        // moreItemNode.style.backgroundColor = 'blue'

        function atLast() {
            let firstOutsideNode = null
            for (const node of rootNaviItemNodeList) {
                if (node.offsetTop !== naviTop) {
                    firstOutsideNode = node
                    break
                }
            }

            let countLoop = 0
            moreItemNode.parentNode.insertBefore(moreItemNode, firstOutsideNode)
            while (moreItemNode.offsetTop !== naviTop) {
                if (moreItemNode == moreItemNode.parentNode.firstChild || !moreItemNode.previousSibling || countLoop > 10000) break;
                // console.log('Jump again::', moreItemNode.previousSibling)
                moreItemNode.parentNode.insertBefore(moreItemNode, moreItemNode.previousSibling)
                countLoop ++
            }
            
        }

        function displayNaviRootItem(rootNaviItemNode, display) {
            const moreListItemNode = this.findById(this.mainNode, `heybarMoreListItem_${rootNaviItemNode.dataset.itemKey}`)
            if (display) {
                rootNaviItemNode.style.visibility = null
                rootNaviItemNode.style.opacity    = null
                if (moreListItemNode) { moreListItemNode.style.display = 'none'}
                let showItem = false
                for (const node of moreListNode.childNodes) {
                    if (!node.style.display) {
                        showItem = true
                        break
                    }
                }
                moreItemNode.style.display = showItem ? null : 'none'
                
            } else {
                rootNaviItemNode.style.visibility = 'hidden'
                rootNaviItemNode.style.opacity    = 0
                if (moreListItemNode) { moreListItemNode.style.display = null}
                moreItemNode.style.display = null

            }
            
        }

        if (rootNaviItemNodeList.length > 0) {
            let orderMoreItemTimer = null
            this.naviObserver = new IntersectionObserver(entries => {
                for (const entry of entries) {
                    if (!entry.isIntersecting) {
                        entry.target.style.visibility = 'hidden'
                        entry.target.style.opacity    = 0
                        displayNaviRootItem.bind(this)(entry.target, false)     
                        
                    } else {
                        displayNaviRootItem.bind(this)(entry.target, true)
                    }
                }
                if (orderMoreItemTimer) clearTimeout(orderMoreItemTimer)
                orderMoreItemTimer = setTimeout(atLast.bind(this), 10)

            }, { root: naviListNode, rootMargin: '0px 0px -5px 0px' })

            // this.naviItemObserver = new MutationObserver(function(mutations) {
            //     console.log('=========>>>> ffff:::', mutations)
            //   });

            rootNaviItemNodeList.forEach(node => { 
                // if (node !== rootNaviItemNodeList[0]) {
                    this.naviObserver.observe(node)
                // }
            })
            

            this.moreObserver = new IntersectionObserver(entries => {
                const entry = entries[0]
                if (!entry.isIntersecting) {
                    if (orderMoreItemTimer) clearTimeout(orderMoreItemTimer)
                    orderMoreItemTimer = setTimeout(atLast.bind(this), 1)
                }
                
            }, { root: naviListNode, rootMargin: '0px 0px -5px 0px' })

            this.moreObserver.observe(moreItemNode)
        }
    }

}

Object.assign(Navigation.prototype, domMixin(), eventMixin())
Navigation.id = NAVIGATION_ID

export default Navigation